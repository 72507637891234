/* eslint-disable @typescript-eslint/explicit-function-return-type */
/*
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Wrapper from '../../app/shared/components/Wrapper/Wrapper';
import { I18nNamespaces } from '../../app/shared/utils/constants.util';

interface QueryString {
  mobileUrlScheme?: string;
  code?: string;
}

const RedirectToMobile = () => {
  const { t } = useTranslation(I18nNamespaces.DEFAULT);
  const pathParams: QueryString = queryString.parse(window.location.search);

  useEffect(() => {
    if (pathParams?.mobileUrlScheme && pathParams?.code) {
      window.location.replace(`${pathParams.mobileUrlScheme}://redirectAfterSSOLogin?code=${pathParams.code}`);
    }
  }, []);

  const userInfoMsg = pathParams?.mobileUrlScheme && pathParams?.code
    ? <p>{t('redirectToMobile.infoMsg')}</p> : <p>{t('redirectToMobile.errorMsg')}</p>;

  return (
    <Wrapper>{userInfoMsg}</Wrapper>
  );
};

export default RedirectToMobile;
