/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IlDropdown, IlLoading } from 'il-framework-component';
import ROUTES from '../../app/shared/utils/router.util';
import SideNavigation from '../../app/shared/components/SideNavigation/SideNavigation';
import { I18nNamespaces } from '../../app/shared/utils/constants.util';
import PersonalInfo from './Info/PersonalInfo';
import { setIsLoading } from '../../app/shared/AppSlice/AppSlice';
import { getApplications, getProfile } from './profileSlice';
import { RootState, routerHistory } from '../../app/store';
import UpdatePassword from './UpdatePassword/updatePassword';
import EnrollMFASelect from './EnrollMFA/EnrollMFASelect';
import { getSupportedFactors } from './EnrollMFA/enrollSlice';
import ExternalApplications from './ExternalAppsAccess/externalAppsAccess';

import * as styles from './ProfileHome.scss';

interface ISideBarContent {
  label: string;
  route: string;
}

const ProfileHomeContainer = (): JSX.Element => {
  const { t } = useTranslation(I18nNamespaces.PROFILE);
  const dispatch = useDispatch();
  const location = useLocation();

  const { ssoEnabled } = useSelector((state: RootState) => state.profile.profileDetails);
  const isAppLoading = useSelector((state: RootState) => state.appState.isAppLoading);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const [mobileDropdownList, setMobileDropdownList] = useState<ISideBarContent[]>([]);

  useEffect(() => {
    (async (): Promise<void> => {
      await dispatch(setIsLoading(true));
      await Promise.all([
        dispatch(getProfile()),
        dispatch(getApplications())
      ]);
      await dispatch(setIsLoading(false));
    })();
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!ssoEnabled) {
        await dispatch(setIsLoading(true));
        await dispatch(getSupportedFactors());
        await dispatch(setIsLoading(false));
      }
    })();
  }, [ssoEnabled]);

  const getNavList = (): {label: string; route: string}[] => {
    if (!ssoEnabled) {
      return [
        {
          label: t('profile.profileInfo'),
          route: `${ROUTES.PROFILE_CONTAINER}`
        },
        {
          label: t('profile.accountRecovery'),
          route: `${ROUTES.RECOVERY_SETTINGS_CONTAINER}`
        },
        {
          label: t('profile.updatePassword'),
          route: `${ROUTES.UPDATE_PASSWORD_CONTAINER}`
        }
      ];
    }
    return [{
      label: t('profile.profileInfo'),
      route: `${ROUTES.PROFILE_CONTAINER}`
    }];
  };

  const getSelectedNavItem = (mobileList: ISideBarContent[]): void => {
    const selectId = mobileList.findIndex((list) => list.route === location.pathname);
    setSelectedItemIndex(selectId);
  };

  useEffect(() => {
    const mobileList = getNavList();
    setMobileDropdownList(mobileList);
    getSelectedNavItem(mobileList);
  }, []);

  document.title = t('Account Profile', { ns: I18nNamespaces.PROFILE });

  const dropdownValue = (e: any): void => {
    routerHistory.push(e.selectedItem.route);
    const selectId = mobileDropdownList.findIndex((list) => list.route === e.selectedItem.route);
    setSelectedItemIndex(selectId);
  };

  return (
    <div className={styles.profileAppContainer}>
      { isAppLoading && <IlLoading withOverlay />}

      <div className='bx--row'>
        <div className='bx--col-lg-3 bx--col-xlg-3 bx--col-md-3 bx--col-sm-0'>
          <SideNavigation
            navItems={getNavList()}
          />
        </div>
        <div className='bx--col-lg-0 bx--col-xlg-0 bx--col-md-0  bx--col-sm-4'>
          <div className={styles.profileDropDown}>
            <IlDropdown
              initialSelectedItem={mobileDropdownList[selectedItemIndex]}
              id='profileDropdown'
              items={mobileDropdownList}
              label='Select Profile Dropdown'
              selectedItem={mobileDropdownList[selectedItemIndex]}
              itemToString={(item: ISideBarContent): string => (item ? t(item.label) : '')}
              onChange={(e): void => dropdownValue(e)}
            />

          </div>
        </div>
        <div className='bx--col-lg-13 bx--col-xlg-13 bx--col-md-13 bx--col-sm-4'>
          <Switch>
            <Route exact path={ROUTES.PROFILE_CONTAINER} component={PersonalInfo} />
            <Route exact path={ROUTES.RECOVERY_SETTINGS_CONTAINER} component={EnrollMFASelect} />
            <Route exact path={ROUTES.UPDATE_PASSWORD_CONTAINER} component={UpdatePassword} />
            <Route exact path={ROUTES.EXTERNAL_APP_ACCESS_CONTAINER} component={ExternalApplications} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ProfileHomeContainer;
