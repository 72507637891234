import React, { SyntheticEvent } from 'react';

import * as styles from './QrCode.scss';

interface QrProps {
  source: string;
  alt: string;
  onLoad(event: SyntheticEvent): void;
  hide: boolean;
}

const QrCode: React.FunctionComponent<QrProps> = ({
  source, alt, onLoad: onLoadFunction, hide
}: QrProps) => (
  <img
    onLoad={onLoadFunction}
    className={styles.qr}
    alt={alt}
    src={source}
    style={{
      display: hide ? 'none' : 'block'
    }}
  />
);

export default QrCode;
