/**
 * Copyright 2022, IntraLinks, Inc. All rights reserved.
 */

import React, {
  SyntheticEvent, useEffect, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  IlLoading
} from 'il-framework-component';
import { RootState } from '../../../app/store';
import { I18nNamespaces } from '../../../app/shared/utils/constants.util';
import {
  activateFactor,
  enrollFactor,
  setError,
  setFactorStatus,
  setQrCode,
  setSelectedFactor, setShowAuthForm,
  setShowMFA, setShowPushForm,
  setValidationError
} from './enrollSlice';
import common from '../../../scss/Common.scss';
import BackButton from '../../../app/shared/components/BackButton/BackButton';
import * as authStyle from './Authenticator.scss';
import QrCode from '../../../app/shared/components/QrCode';
import * as styles from './EnrollPush.scss';

interface PushProps {
  type: 'Okta' | 'Intralinks';
}

const EnrollPush: (props: PushProps) => JSX.Element = (props: PushProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const qrCode = useSelector((state: RootState) => state.enroll.qrCode);
  const selectedFactor = useSelector((state: RootState) => state.enroll.selectedFactor);
  const factorStatus = useSelector((state: RootState) => state.enroll.factorStatus);
  const { type } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(I18nNamespaces.PROFILE);

  let title = '';
  let instructions = '';
  let provider = '';

  switch (type) {
    case 'Okta':
    default:
      title = t('mfa.USE_OKTA_PUSH');
      instructions = t('mfa.OKTA_PUSH_ENROLL_HELP');
      provider = 'OKTA';
      break;
  }
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const checkActivationStatus = (): void => {
    if (selectedFactor?.id) {
      dispatch(activateFactor(t, selectedFactor.id));
    }
    timeout.current = setTimeout(checkActivationStatus, 6000);
  };

  useEffect(() => {
    if (selectedFactor?.id) {
      timeout.current = setTimeout(checkActivationStatus, 1000);
    }
    return (): void => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [selectedFactor]);

  useEffect(() => {
    dispatch(enrollFactor(t, 'push', 'provider', provider));
    return () => {
      dispatch(setQrCode(''));
      dispatch(setSelectedFactor(undefined));
    };
  }, []);

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    setIsImageLoading(false);
    event.preventDefault();
  };
  const handleCantScanClick = (event: SyntheticEvent<HTMLAnchorElement, Event>) => {
    dispatch(setShowPushForm(false));
    dispatch(setShowAuthForm(true));
    event.preventDefault();
  };

  const backLinkHandler = (): void => {
    dispatch(setError(''));
    dispatch(setQrCode(''));
    if (factorStatus) {
      dispatch(setFactorStatus(''));
      dispatch(setValidationError(''));
      dispatch(setError(''));
    }
    dispatch(setShowMFA(true));
    dispatch(setShowPushForm(false));
  };

  return (
    <div>
      <div className={common.ilForm}>
        <BackButton title={t('BACK_TO_PREVIOUS_PAGE')} onClick={backLinkHandler} className={authStyle.backLink} />
        <h1 className={classnames(common.formTitle, common.noPadding)} id='testAuthenticateTitle'>
          {title}
        </h1>
        <p className={classnames(common.helpText, authStyle.helpText)} id='testAuthHelpText'>
          {instructions}
        </p>
        <div className={authStyle.qrContainer}>
          {
              isImageLoading ? <IlLoading className={authStyle.loader} withOverlay={false} /> : null
          }
          <QrCode onLoad={onImageLoad} hide={isImageLoading} source={qrCode} alt='Qr code' />
        </div>
        {!isImageLoading && (
        <div className={styles.cantScanContainer}>
          <a type='button' href='#' className={styles.cantScanButton} onClick={handleCantScanClick}>
            <span>{t('mfa.CANT_SCAN_CODE')}</span>
          </a>
        </div>
        )}
      </div>
    </div>
  );
};

export default EnrollPush;
