/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { replace } from 'connected-react-router';
import { TFunction } from 'i18next';
import { AppThunk } from '../../app/store';
import Repos from '../../infrastructure/repositories/Repos';
import { IProfile, IProfileProperties } from '../../domain/models/IProfile';
import { IProfileExternalApps } from '../../domain/models/IProfileExternalApps';
import { setIsLoading, showToast } from '../../app/shared/AppSlice/AppSlice';
import { I18nNamespaces, getErrorPath } from '../../app/shared/utils/constants.util';
import { getTranslatedErrorKey, getErrorStatusCode } from '../../app/shared/utils/errors.util';
import { IGetListQueryParams } from '../../infrastructure/repositories/utils/IGetListQueryParams';

export interface IProfileState {
  profileDetails: IProfileProperties;
  applications: any[];
  externalApps: IProfileExternalApps[];
  profileSetupDetails?: Partial<IProfileProperties>;
}

const initialState: IProfileState = {
  profileDetails: {
    id: '',
    externalId: '',
    preferences: {
      locale: '',
      timezone: ''
    },
    name: {
      displayName: '',
      firstName: '',
      lastName: ''
    },
    phoneInfos: [],
    addresses: [],
    identityProviderId: '',
    ssoEnabled: false,
    title: '',
    industry: ''
  },
  applications: [],
  externalApps: [],
  profileSetupDetails: undefined
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    currentProfile: (state: IProfileState, action: PayloadAction<any>): void => {
      state.profileDetails = action.payload.allProfile;
    },
    setUpProfile: (state: IProfileState, action: PayloadAction<any>): void => {
      state.profileSetupDetails = action.payload.allProfile;
    },
    setApplications: (state: IProfileState, action: PayloadAction<any>): void => {
      state.applications = action.payload;
    },
    updateProfileData: (state: IProfileState, action: PayloadAction<any>): void => {
      state.profileDetails = action.payload.updateProfile;
    },
    setExternalApplications: (state: IProfileState, action: PayloadAction<any>): void => {
      state.externalApps = action.payload;
    }
  }
});

export const {
  setUpProfile, updateProfileData, currentProfile, setApplications, setExternalApplications
} = profileSlice.actions;
export const getProfileForEulaCheck = (fields?: (keyof IProfile)[] | undefined): AppThunk => async (dispatch): Promise<void> => {
  // eslint-disable-next-line no-useless-catch
  try {
    dispatch(setIsLoading(true));
    const allProfile = await Repos.instance.profileRepository.get(undefined, { fields } as IGetListQueryParams<IProfile>);
    if (allProfile?.eulaVersion === 1 && !allProfile?.eulaDeclined) {
      dispatch(replace('/profile'));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    throw error;
  }
};
export const getProfileForProfileSetup = (fields?: (keyof IProfile)[] | undefined): AppThunk => async (dispatch): Promise<void> => {
  // eslint-disable-next-line no-useless-catch
  try {
    dispatch(setIsLoading(true));
    const allProfile = await Repos.instance.profileRepository.get(undefined, { fields } as IGetListQueryParams<IProfile>);
    dispatch(setUpProfile({ allProfile }));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    throw error;
  }
};
export const getProfile = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    const allProfile = await Repos.instance.profileRepository.get(undefined, undefined);
    dispatch(currentProfile({ allProfile }));
  } catch (error) {
    const errorStatusCode = error?.response?.data?._status?.status;
    const errorCode = getErrorStatusCode(errorStatusCode) || '500';
    dispatch(replace(getErrorPath(errorCode)));
  }
};

export const getApplications = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    const response = await Repos.instance.profileAppRepository.getAll(undefined, undefined);
    dispatch(setApplications(response.items));
  } catch (error) {
    const errorStatusCode = error?.response?.data?._status?.status;
    const errorCode = getErrorStatusCode(errorStatusCode) || '500';
    dispatch(replace(getErrorPath(errorCode)));
  }
};
export const profileSetup = (req: any): AppThunk => async (): Promise<void> => {
  req.id = '';
  await Repos.instance.profileRepository.update(req, '');
};
export const getUpdateProfile = (updateProfileObj: any, t: TFunction): AppThunk => async (dispatch): Promise<void> => {
  updateProfileObj.id = '';
  try {
    const updateProfile = await Repos.instance.profileRepository.update(updateProfileObj, '');
    dispatch(updateProfileData({ updateProfile }));
    dispatch(showToast({
      title: t('successMessages.profileInfoUpdated', { ns: I18nNamespaces.PROFILE }),
      timeout: 0 as number,
      kind: 'success',
      subtitle: ''
    }));
  } catch (err) {
    const translatedErrorKey = getTranslatedErrorKey(err);
    dispatch(showToast({
      title: t(`${translatedErrorKey}`, { ns: I18nNamespaces.PROFILE }), timeout: 0 as number, kind: 'error', subtitle: ''
    }));
  }
};

export const getExternalApps = (id: string, t: TFunction): AppThunk => async (dispatch): Promise<void> => {
  try {
    const response = await Repos.instance.profileExternalAppsRepository.getAll(undefined, { id });
    dispatch(setExternalApplications(response.items));
  } catch (error) {
    const errorStatusCode = error?.response?.data?._status?.status;
    const errorCode = getErrorStatusCode(errorStatusCode) || '500';
    dispatch(showToast({
      title: t('externalApps.messages.getExternalApps.error', { ns: I18nNamespaces.PROFILE }),
      timeout: 0 as number,
      kind: 'error',
      subtitle: ''
    }));
    throw errorCode;
  }
};

export const deleteExternalApp = (id: string, consentId: string): AppThunk => async (): Promise<void> => {
  try {
    await Repos.instance.profileExternalAppsRepository.delete('', { id, consentId });
  } catch (error) {
    const errorStatusCode = error?.response?.data?._status?.status;
    const errorCode = getErrorStatusCode(errorStatusCode) || '500';
    throw errorCode;
  }
};

export default profileSlice.reducer;
