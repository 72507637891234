/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ERROR_IMAGE from '../../assets/Illustration-Error.svg';
import ERROR_TEAPOT from '../../assets/Illustration-Teapot.svg';
import { I18nNamespaces, SUPPORT_URL } from '../../app/shared/utils/constants.util';
import * as Styles from './Errorcomponent.scss';
import { setCoreAppState } from './errorPageSlice';
import { AppDispatch } from '../../app/store';

interface ErrorComponentProps extends WithTranslation, IDispatchProps {
    location: any;
}

interface IDispatchProps {
    dispatchsetAppsCore: () => void;
}

class ErrorComponent extends React.Component<ErrorComponentProps> {
  componentDidMount() {
    const { dispatchsetAppsCore } = this.props;
    dispatchsetAppsCore();
  }

  getErrorTitle = (path: string, t: any): string => {
    if (path.toLowerCase() === 'maintenance') {
      return t(`${path}.title`);
    }
    if (path === '404' || path === '418') return `${path}:  ${t(`${path}.title`)} `;
    return `${path} ${t('errorLabel')} `;
  };

  renderErrorDOM(errorPath: string, errorContent: string, t: any): JSX.Element {
    return (
      <div style={{ whiteSpace: 'pre-line' }}>
        <div className={Styles['error-title']}>
          {this.getErrorTitle(errorPath, t)}
        </div>
        <div className={Styles['error-content']}>
          {' '}
          {errorPath !== '404' ? t(`${errorPath}.content`) : errorContent}
        </div>
      </div>
    );
  };

  renderErrorData(path: string, content: string, t: any): JSX.Element {
    return (
      <>
        {this.renderErrorDOM(path, content, t)}
        { path.toLowerCase() !== 'maintenance'
        && (
        <div className={Styles['error-support']}>
          {' '}
          {t('commonContent')}{' '}
          <a
            href={SUPPORT_URL}
            onClick={() => window.open(SUPPORT_URL, '_blank', 'width=500,height=400')}
            target='_blank'
            rel='noreferrer'
          >
            {t('supportLink')}
          </a>
          .{' '}
        </div>
        )}
      </>
    );
  }

  render() {
    const { t, location } = this.props;
    const { path, content } = location.state;
    return (
      <div className={Styles['main-error-container']}>
        <span className={Styles['error-text-container']}>
          {this.renderErrorData(path, content, t)}
        </span>
        {path !== '418'
          ? (
            <span className={Styles['error-image']}>
              <img className={Styles['error-img']} src={ERROR_IMAGE} alt='Error' />
            </span>
          )
          : (
            <span className={Styles['error-teapot-img']}>
              <img className={Styles['error-teapot-image']} src={ERROR_TEAPOT} alt='Error' />
            </span>
          )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): IDispatchProps => ({
  dispatchsetAppsCore: (): void => dispatch(setCoreAppState())
});

export default connect(
  null, mapDispatchToProps
)(withTranslation([I18nNamespaces.ERROR])(ErrorComponent));
