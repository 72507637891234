/**
 * Copyright 2022, IntraLinks, Inc. All rights reserved.
 */

import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './SideNavigation.scss';

const ACTIVE_CLASS_NAME = 'active';

export interface INavItem {
  label: string;
  route: string;
  className?: string;
}

interface ISideNavigationProps {
  navItems: INavItem[];
}

const SideNavigation: React.FC<ISideNavigationProps> = (props: ISideNavigationProps) => {
  const { navItems } = props;

  const renderItems = ({ label, route }: INavItem) => (
    <li>
      <NavLink
        key={label}
        exact
        className={styles.NavLink}
        activeClassName={ACTIVE_CLASS_NAME}
        to={route}
      >
        {label}
      </NavLink>
    </li>
  );

  return (
    <nav>
      <ul>
        {navItems.map(renderItems)}
      </ul>
    </nav>
  );
};

export default SideNavigation;
