/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'reflect-metadata';
import 'element-closest-polyfill';
import 'element-matches-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {
  initAppsCore,
  onApiResponseError,
  onApiResponseSuccess
} from '@intralinks/apps-core';
import { Route, Switch } from 'react-router';
import { initialize as initializeConfig } from './app/shared/utils/config.util';
import { initializeI18n } from './i18n';
import Repos, { IReposConfigureOptions } from './infrastructure/repositories/Repos';
import { store, routerHistory } from './app/store';
import App from './App';
import Eula from './features/EULAScreen/Eula';
import Consent from './features/Consent/Consent';
import RedirectToMobile from './features/RedirectToMobile/RedirectToMobile';
import { unauthenticatedPaths } from './app/shared/utils/constants.util';

/* eslint implicit-arrow-linebreak: ["error", "below"] */
const shouldIgnoreNetworkError = (error: any) => {
  const isPathMatched = unauthenticatedPaths.some((value) =>
    window.location.href.indexOf(value) > -1);
  return isPathMatched && (error?.response?.status === 401 || error?.response?.status === 403);
};

Promise.all([initializeConfig(), initializeI18n()]).then(async ([config]) => {
  const {
    appName,
    profileAppName,
    appId,
    apiBaseUrl,
    iamConfig,
    authBaseUrl,
    csrfEnabled,
    gatewayBaseUrl
  } = config;

  const repoInstanceConfig: IReposConfigureOptions = {
    apiBaseUrl,
    authBaseUrl,
    csrfEnabled,
    gatewayBaseUrl,
    interceptors: {
      responseInterceptor: {
        onResponseError: onApiResponseError,
        onResponseSuccess: onApiResponseSuccess
      }
    }
  };

  const csrf = await initAppsCore({ iamConfig, gatewayBaseUrl, shouldIgnoreNetworkError });

  if (csrf) {
    repoInstanceConfig.csrfToken = csrf;
  }

  await Repos.instance.configure(repoInstanceConfig);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={routerHistory}>
          <Switch>
            <Route exact path='/eula' component={Eula} />
            <Route path='/login-consent' component={Consent} />
            <Route path='/redirectToMobileApp' component={RedirectToMobile} />
            <Route
              exact
              path='/*'
              component={() =>
                (
                  <App
                    appName={appName}
                    appId={appId}
                    profileAppName={profileAppName}
                  />
                )}
            />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
