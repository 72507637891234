/*
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

import React from 'react';
import { routerHistory } from '../../app/store';

class ProfileRoute extends React.Component {
  componentDidMount() {
    routerHistory.push('/profile/info');
  }

  render(): JSX.Element {
    return (
      <div />
    );
  }
}

export default ProfileRoute;
