/**
 * Copyright 2024, IntraLinks, Inc. All rights reserved.
 * @author ssurisetti@intralinks.com
 */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IlStates } from 'il-framework-component';
import { setCoreAppState } from '../errorPageSlice';
import { I18nNamespaces, SUPPORT_URL } from '../../../app/shared/utils/constants.util';
import styles from './ChartsErrorComponent.scss';

export default function ChartErrorComponent(): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation(I18nNamespaces.ERROR);

  useEffect(() => {
    (async () => {
      await dispatch(setCoreAppState());
    })();
  }, []);

  const renderDescription = () => (
    <div>
      <div className={styles.chartDescription}>{t('charts.description')}</div>
      <div className={styles.chartDescription}>
        {' '}
        {t('commonContent')}{' '}
        <a
          href={SUPPORT_URL}
          onClick={() => window.open(SUPPORT_URL, '_blank', 'width=500,height=400')}
          target='_blank'
          rel='noreferrer'
        >
          {t('supportLink')}
        </a>
        .{' '}
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.chartsErrorComponent}>
        <IlStates
          illustration='error'
          title={t('charts.title')}
          renderContent={renderDescription}
        />
      </div>
    </>
  );
}
