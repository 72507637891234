/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

import {
  IGetListQueryParams,
  SortCriteria,
  ISort,
  IQueryFilterType,
  ComplexQueryType,
  LogicalFilterValueType,
  OrType,
  AndType
} from './IGetListQueryParams';
import { IAdaptor } from './IAdaptor';

export interface IApiGetListQueryParams {
  sort?: string;
  // TODO: remove object type
  // eslint-disable-next-line @typescript-eslint/ban-types
  filter?: string | object;
  fields?: string;
  limit?: number;
  offset?: number;
}

const parseSort = (sort?: ISort[]): { [propName: string]: SortCriteria }[] => (sort || [])
  .filter(({ direction }) => direction !== SortCriteria.None)
  .map(({ column, direction }) => ({ [column]: direction }));

const adaptSortToDto = <M, D>(
  map: { [K in keyof M]?: keyof D },
  sort?: ISort[]
): ISort[] => (sort ? sort.map((s: ISort): ISort => ({ column: map[s.column as keyof M] as string, direction: s.direction })) : []);

const adaptFilterToDto = <M, D>(
  filter: IQueryFilterType<M>,
  map: { [K in keyof M]?: keyof D }
): IQueryFilterType<D> => {
  function parse(filterObject: IQueryFilterType<M>): IQueryFilterType<D> {
    const newFilter: IQueryFilterType<D> = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isObjectLiteral = (a: any): boolean => !!a && a.constructor === Object;
    Object.keys(filterObject).forEach((key) => {
      const dtoKey = map[key as keyof M];
      const castedDtoKey: keyof D | keyof ComplexQueryType<D> = dtoKey
        ? ((dtoKey as unknown) as keyof D)
        : ((key as unknown) as keyof ComplexQueryType<D>);
      const value = filterObject[key as keyof IQueryFilterType<M>];
      if (Array.isArray(value)) {
        // for arrays with object literals like { myProp: myValue }
        const isArrayWithObjectLiterals = (value as Array<
            IQueryFilterType<M>
        >).every(isObjectLiteral);
        if (isArrayWithObjectLiterals) {
          type LogicalFilterKeys = keyof (OrType<D> & AndType<D>);
          // cannot index with a string key
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment,@typescript-eslint/ban-ts-ignore
          // @ts-ignore
          newFilter[
              castedDtoKey as LogicalFilterKeys
          ] = (value as LogicalFilterValueType<M>).map(parse);
        } else {
          newFilter[castedDtoKey] = value;
        }
      } else if (typeof value === 'object') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment,@typescript-eslint/ban-ts-ignore
        // @ts-ignore
        newFilter[castedDtoKey] = parse(value);
      } else {
        newFilter[castedDtoKey] = value;
      }
    });
    return newFilter;
  }

  return parse(filter);
};

const adaptFieldsToDto = <M, D>(
  fields: (keyof M)[],
  map: { [K in keyof M]?: keyof D }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
): (keyof D)[] => fields.map((el: keyof M) => map[el]!);

export const adaptQueryParamsToDto = <M, D, T extends IGetListQueryParams<M>>(
  queryParams: T,
  adapter: IAdaptor<M, D>
): Omit<T, keyof IGetListQueryParams<M>> & IGetListQueryParams<D> => {
  const {
    sort,
    filter,
    fields,
    limit,
    offset,
    ...customQueryParams
  } = queryParams;
  const { domainToDtoPropertiesMap } = adapter;
  const queryParamsClone: IGetListQueryParams<D> = {};
  if (limit) {
    queryParamsClone.limit = limit;
  }
  if (offset) {
    queryParamsClone.offset = offset;
  }
  if (sort) {
    queryParamsClone.sort = adaptSortToDto(domainToDtoPropertiesMap, sort);
  }
  if (filter) {
    queryParamsClone.filter = adaptFilterToDto(
      filter,
      domainToDtoPropertiesMap
    );
  }
  if (fields) {
    queryParamsClone.fields = adaptFieldsToDto(
      fields,
      domainToDtoPropertiesMap
    );
  }
  return { ...queryParamsClone, ...customQueryParams };
};

export const composeQueryParams = <D, Q extends IGetListQueryParams<D>>(
  queryParams: Q
): Omit<Q, keyof IGetListQueryParams<D>> & IApiGetListQueryParams => {
  const {
    sort, filter, fields, limit, offset, ...customParams
  } = queryParams;
  const params: IApiGetListQueryParams = { limit, offset };

  const parsedSort = parseSort(sort);

  params.sort = parsedSort && parsedSort.length > 0
    ? JSON.stringify(parsedSort)
    : undefined;
  params.filter = filter;

  params.fields = fields && fields.length > 0 ? fields.join() : undefined;

  return { ...params, ...customParams };
};
