/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import React from 'react';
import IconV2, { IconV2SizeEnum } from '../Icon/IconV2';

interface BackButtonProps {
  title: string;
  onClick: () => void;
  className: string;
}

const BackButton: React.FC<BackButtonProps> = (props: BackButtonProps): JSX.Element => {
  const { title } = props;

  return (
    <a id='go-back' {...props}>
      <IconV2 name='arrow-left' size={IconV2SizeEnum.ExtraSmall} className='backArrow' /><span>{title}</span>
    </a>
  );
};
export default BackButton;
