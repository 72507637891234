/*
 * Copyright 2024, IntraLinks, Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';

import {
  IlButton, IlSelect, IlSelectItem, IlTextInput
} from 'il-framework-component';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { I18nNamespaces } from '../../app/shared/utils/constants.util';
import * as styles from '../Profile/Info/ProfileInfo.scss';
import * as Styles from './Eula.scss';

import { LANGUAGE_TYPES } from '../../app/shared/utils/profile.util';
import { IProfileProperties } from '../../domain/models/IProfile';

interface IProfileSetupComponentProps {
    // eslint-disable-next-line react/require-default-props
    profileDetails?: Partial<IProfileProperties>;
    onSave: (profile: Partial<IProfileProperties>) => Promise<void>;
    // eslint-disable-next-line react/require-default-props
    eulaActivated?: boolean;
}
interface IInitialProfile {
    firstName?: string;
    lastName?: string;
    language?: string;
}

const ProfileSetupComponent = (props: IProfileSetupComponentProps) => {
  const { profileDetails, eulaActivated = false, onSave } = props;
  const { t } = useTranslation(I18nNamespaces.PROFILE);

  const [profile, setProfile] = useState<IInitialProfile>({
    firstName: undefined,
    language: 'EN_US',
    lastName: undefined
  });
  const [firstNameInvalid, setFirstNameInvalid] = useState<boolean>(false);
  const [lastNameInvalid, setLastNameInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (profileDetails) {
      setProfile({
        firstName: profileDetails?.name?.firstName,
        lastName: profileDetails?.name?.lastName,
        language: profileDetails?.preferences?.locale
      });
    }
  }, [profileDetails]);

  const updateFields = (e: any) => {
    const { id } = e.target;
    const { value } = e.target;
    if (!value || value === '') {
      if (id === 'firstName') { setFirstNameInvalid(true); } else if (id === 'lastName') { setLastNameInvalid(true); }
    } else if (id === 'firstName') { setFirstNameInvalid(false); } else if (id === 'lastName') { setLastNameInvalid(false); }
    setProfile({ ...profile, [id]: value });
  };
  const onSaveBtnClick = async () => {
    await onSave({
      name: {
        firstName: profile.firstName,
        lastName: profile.lastName,
        displayName: `${profile.firstName} ${profile.lastName}`
      },
      preferences: {
        locale: profile.language
      }
    });
  };

  return (
    <div className={classNames(Styles.profileSetupContainer)} aria-disabled={!eulaActivated}>
      <h6>{t('profileInformation.setYourProfile')}</h6>
      {profileDetails?.externalId
      && (
      <div className={Styles.emailContainer}>
        <span>{t('profileInformation.externalId')}</span><span>{profileDetails?.externalId}</span>
      </div>
)}
      <IlTextInput
        id='firstName'
        placeholder={t('profileInformation.firstname')}
        labelText={t('profileInformation.firstname')}
        value={profile.firstName}
        invalidText={t('profileInformation.invalid_text')}
        invalid={firstNameInvalid}
        disabled={!eulaActivated}
        onChange={(e) => updateFields(e)}
        onBlur={(e) => updateFields(e)}
      />
      <IlTextInput
        id='lastName'
        disabled={!eulaActivated}
        placeholder={t('profileInformation.lastname')}
        labelText={t('profileInformation.lastname')}
        value={profile.lastName}
        invalidText={t('profileInformation.invalid_text')}
        invalid={lastNameInvalid}
        onChange={(e) => updateFields(e)}
        onBlur={(e) => updateFields(e)}
      />
      <IlSelect
        id='language'
        onChange={(e) => updateFields(e)}
        labelText={t('profileInformation.titleLanguage')}
        className={styles.selectFields}
        value={profile.language}
        defaultValue={profile.language}
        disabled={!eulaActivated}
      >
        {_.map(LANGUAGE_TYPES, (value, key) => (
          <IlSelectItem
            key={key}
            value={key}
            text={value}
          />
        ))}
      </IlSelect>
      <div className={styles.btnContainer}>
        <IlButton
          id='saveProfileSetup'
          kind='primary'
          size='field'
          type='button'
          onClick={() => onSaveBtnClick()}
          disabled={firstNameInvalid || lastNameInvalid || !profile.firstName || !profile.lastName}
        >{t('profileInformation.profileSave')}
        </IlButton>
      </div>
    </div>

  );
};
export default ProfileSetupComponent;
