/*
 * Copyright 2022, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable class-methods-use-this */
import { IAdaptor } from '../utils/IAdaptor';
import { IProfileExternalApps } from '../../../domain/models/IProfileExternalApps';
import ProfileExternalAppsDto from '../../dto/ProfileExtrenalAppsDto';

export default class ProfileAdaptor implements IAdaptor<IProfileExternalApps, ProfileExternalAppsDto> {
    /**
     * This property is used to map properties from FE to properties in BE, such that when requesting some fields,
     * or filtering by some fields, the infrastructure layer knows what to request from the BE.
     * */
    domainToDtoPropertiesMap: { [K in keyof IProfileExternalApps]?: keyof ProfileExternalAppsDto } = {
      id: 'id',
      extAppName: 'extAppName',
      scopes: 'scopes',
      createdAt: 'createdAt'
    };

    /**
     * This is used mostly when we do get calls, so that data from BE is properly mapped to data in FE
     */
    fromDto(dto: ProfileExternalAppsDto): Partial<IProfileExternalApps> {
      return {
        id: dto.id,
        extAppName: dto.extAppName,
        scopes: dto.scopes,
        createdAt: dto.createdAt
      };
    }

    /**
     * This function is used to map FE Domain models to BE models.
     * It will be called when we send a domain model to the infrastructure, and it needs to send a Dto to BE (post, patch, put).
     * Bear in mind that you can just write the properties that you use,
     * no need to add the properties that you don't need to send over the network.
     */
    toDto(profile: Partial<IProfileExternalApps>): Partial<ProfileExternalAppsDto> {
      return {
        id: profile.id,
        extAppName: profile.extAppName,
        scopes: profile.scopes,
        createdAt: profile.createdAt
      };
    }
}
