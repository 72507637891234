/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/*
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash';
import {
  IlButton, IlIconInitializer, IlCheckbox
} from 'il-framework-component';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  EULA_ACTIONS, I18nNamespaces
} from '../../app/shared/utils/constants.util';
import * as Styles from './Consent.scss';
import Wrapper from '../../app/shared/components/Wrapper/Wrapper';
import { consentAccepted, consentDeclined, consentScopes } from './ConsentSlice';
import IconV2 from '../../app/shared/components/Icon/IconV2';
import { RootState } from '../../app/store';

interface QueryString {
  externalappname?: string;
}

const Consent = () => {
  const path: QueryString = queryString.parse(window.location.search);
  const [scopes, setScopes] = useState<string[]>([]);
  const consentScopeDetails = useSelector((state: RootState) => state.consent.scopes);
  const dispatch = useDispatch();
  const { t } = useTranslation(I18nNamespaces.DEFAULT);
  // eslint-disable-next-line array-callback-return
  consentScopeDetails.map((consent) => {
    if (scopes.indexOf(consent.id) === -1) scopes.push(consent.id);
  });
  const onDeclineClicked = async (action: string) => {
    await dispatch(consentDeclined(action));
  };

  const onAccept = async (action: string) => {
    await dispatch(consentAccepted(action, scopes));
  };

  const handleCheckboxClick = (value: boolean, id: string) => {
    if (value) {
      if (scopes.indexOf(id) === -1) scopes.push(id);
    } else {
      remove(scopes, (scopeId) => scopeId === id);
    }
    setScopes(scopes);
  };

  useEffect(() => {
    (async () => {
      await dispatch(consentScopes());
    })();
  }, []);

  return (
    <>
      <IlIconInitializer />
      <Wrapper>
        <div className={Styles['consent-body']}>
          <p>
            <p className={Styles['consent-header']}>
              {path.externalappname}
              {' '}
              {t('consent.header')}
            </p>
            <p className={Styles['consent-before-block']}>
              {t('consent.subheader')}
            </p>
          </p>
          <div className={Styles['scope-list']}>
            <div className={Styles['scope-list-item']}>
              <div className={Styles.profileScopeListItem}>
                <div>
                  <IlCheckbox
                    checked
                    id='profileScope'
                    labelText={t('consent.profileInfo')}
                    wrapperClassName={Styles.profileScopeListItem}
                    disabled
                  />
                </div>
                <div className={Styles['icon-holder']}>
                  <IconV2
                    name='info'
                    className='iconFillPrimary'
                    tooltip={{
                      body: (
                        <span>
                          {t('consent.profileInfoTooltip')}
                        </span>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={Styles['scope-separator']} />
            {
              consentScopeDetails.map((scope) => (
                scope.allowed
                  ? (
                    <div key={scope.id} className={Styles['scope-list-item']}>
                      <div>
                        <IlCheckbox
                          id={scope.id}
                          labelText={scope.name}
                          defaultChecked
                          wrapperClassName={Styles.profileScopeListItem}
                          onChange={(value) => handleCheckboxClick(value, scope.id)}
                        />
                      </div>
                    </div>
                  )
                  : (
                  // Scope NOT allowed
                    <div key={scope.id} className={Styles['scope-list-item']}>
                      <div className={classNames(Styles.profileScopeListItem, Styles.addPaddingTopBottom)}>
                        <span className={Styles['icon-warning']}>
                          <IconV2
                            name='warning'
                            className='iconFillRedColor'
                          />
                        </span>
                        <label>
                          {scope.name}
                        </label>
                        <IconV2
                          name='info'
                          className='iconFillPrimary'
                          tooltip={{
                            body: (
                              <span>
                                {t('consent.scopeNotAllowedTooltip')}
                              </span>
                            )
                          }}
                        />
                      </div>
                    </div>
                  )
              ))
          }
          </div>
          <div className={Styles['il-btn-container']}>
            <IlButton
              className={Styles.btn}
              size='small'
              kind='secondary'
              onClick={(): Promise<void> => onDeclineClicked(EULA_ACTIONS.DECLINE)}
            >
              {t('consent.declineButton')}
            </IlButton>

            <IlButton
              className={Styles.btn}
              size='small'
              kind='primary'
              onClick={(): Promise<void> => onAccept(EULA_ACTIONS.ACCEPT)}
            >
              {t('consent.acceptButton')}
            </IlButton>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Consent;
