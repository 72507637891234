/*
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import { IMFARepository } from '../IMFARepository';
import { RequestsHandlerInstance } from '../utils/RequestsHandler';
import { AuthenticatorType } from '../../../app/shared/utils/constants.util';

export default class MFARepository implements IMFARepository {
  constructor(public requestsHandler: RequestsHandlerInstance, public rootResourcePath: string, public authBaseUrl: string) {}

  getSupportedFactors(): Promise<any> {
    return this.requestsHandler.get(
      `${this.authBaseUrl}/${this.rootResourcePath}/factors`,
      {
        headers: { 'Accept-version': 'v2' }
      }
    );
  }

  enrollFactor(factorType: string, additionalKey: string, additionalValue: string): Promise<any> {
    return this.requestsHandler.post(
      `${this.authBaseUrl}/${this.rootResourcePath}/factors`,
      {
        data: {
          factorType,
          [additionalKey]: additionalValue
        }
      }
    );
  }

  activateFactor(factorId: string, activationCode?: string, authenticatorType?: AuthenticatorType): Promise<any> {
    return this.requestsHandler.post(
      `${this.authBaseUrl}/${this.rootResourcePath}/factors/${factorId}/activation`,
      {
        data: {
          activationCode,
          authenticatorType
        }
      }
    );
  }

  verify(token: string, factorId: string, verificationCode: string, rememberDevice = true): Promise<any> {
    return this.requestsHandler.post(
      `auth/factors/${factorId}/verify`,
      {
        data: {
          verificationCode,
          rememberDevice
        }
      },
      {
        headers: { 'x-il-authn-partial-token': token }
      }
    );
  }

  deleteEnrolledFactors(id: string): Promise<any> {
    return this.requestsHandler.delete(`${this.authBaseUrl}/${this.rootResourcePath}/factors/${id}`);
  }
}
