/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Logo from '../../../../assets/SSNCLogo.svg';
import { I18nNamespaces } from '../../utils/constants.util';
import * as Styles from './Wrapper.scss';

interface WrapperProps extends WithTranslation {
    children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children, t }: WrapperProps) => (
  <div className={Styles.wrapper}>
    <div className={Styles.innerwrapper}>
      <div className={Styles.container}>
        <img className={Styles.logo} alt='SS&C Intralinks' src={Logo} />
        { children }
        <div className={Styles.footer}>
          <div className={Styles.copyright}>
            {`© ${new Date().getFullYear()}${t('logout.COPY_RIGHTS')}`}
          </div>
          <div className={Styles.legal}>
            <a href='https://www.intralinks.com/privacy' target='_blank' rel='noreferrer'>{t('logout.PRIVACY_POLICY')}</a>
            {' | '}
            <a href='https://www.intralinks.com/legal' target='_blank' rel='noreferrer'>{t('logout.LEGAL_NOTICES')}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withTranslation(I18nNamespaces.DEFAULT)(Wrapper);
