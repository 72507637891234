/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  IlButton, IlIcon, IlIconInitializer, IlModalV2
} from 'il-framework-component';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  EULA_ACTIONS, EULA_HREF, EULA_ITEMS, I18nNamespaces
} from '../../app/shared/utils/constants.util';
import * as Styles from './Eula.scss';
import Wrapper from '../../app/shared/components/Wrapper/Wrapper';
import { AppDispatch, RootState } from '../../app/store';
import eulaAccepted from './EulaSlice';
import { IProfile, IProfileProperties } from '../../domain/models/IProfile';
import { getProfileForEulaCheck, getProfileForProfileSetup, profileSetup } from '../Profile/profileSlice';
import ProfileSetupComponent from './ProfileSetup';

interface EulaProps extends WithTranslation, IDispatchProps, IStateProps {
}

interface IState {
  hideConfirmModal: boolean;
  btnDisabled: boolean;
  redirectUrl?: string;
  eulaStatusUpdated?: boolean;
  eulaCollapsed?: boolean;
}

class Eula extends React.Component<EulaProps, IState> {
  constructor(props: EulaProps) {
    super(props);
    this.state = {
      hideConfirmModal: true,
      btnDisabled: false,
      redirectUrl: undefined,
      eulaStatusUpdated: false,
      eulaCollapsed: false
    };
  }

  async componentDidMount(): Promise<void> {
    const { dispatchGetProfile } = this.props;
    await dispatchGetProfile(['name', 'preferences', 'eulaDeclined', 'eulaVersion']);
  }

  onDeclineClicked = () => {
    this.setState({ hideConfirmModal: false });
  };

  onCloseClicked = () => {
    this.setState({ hideConfirmModal: true });
  };

  async onAccept(action: string) {
    const { dispatchEulaAccepted, dispatchGetProfileProfileInfo } = this.props;
    this.setState({ btnDisabled: true });
    // eslint-disable-next-line no-useless-catch
    try {
      const response: any = await dispatchEulaAccepted(action);
      const redirectUrl = response && response.data && response.data.data ? response.data.data.redirecturl : response.request.responseURL;
      if (action === EULA_ACTIONS.ACCEPT) {
        await dispatchGetProfileProfileInfo(['name', 'preferences']);
        this.setState({ redirectUrl, eulaStatusUpdated: true, eulaCollapsed: true });
      } else {
        window.location.href = redirectUrl;
      }
    } catch (err) {
      throw err;
    }
  }

  async onProfileSetup(profileInfo: Partial<IProfileProperties>) {
    const { dispatchUpdateProfileSetup } = this.props;
    const { redirectUrl } = this.state;
    try {
      await dispatchUpdateProfileSetup(profileInfo);
      window.location.href = redirectUrl as string;
    } catch (err) {
      window.location.href = redirectUrl as string;
    }
  };

  renderModal = () => {
    const { t } = this.props;
    return (
      <div className={Styles['eula-confirm-modal']}>
        <form>
          <IlModalV2
            dialogBody={t('eula.declineText')}
            size='xs'
            modalHeading={t('eula.confirmTitle')}
            passiveModal
            open
            onRequestClose={() => this.onCloseClicked()}
            preventCloseOnClickOutside
            selectorPrimaryFocus='#eulaGoBackBtn'
            primaryButtonText={t('eula.decline')}
            primaryButtonId='eulaDeclineBtn'
            secondaryButtonText={t('eula.goBack')}
            secondaryButtonId='eulaGoBackBtn'
            tertiaryButtonText=''
            primaryButtonClick={() => this.onAccept(EULA_ACTIONS.DECLINE)}
            secondaryButtonClick={() => this.onCloseClicked()}
          />
        </form>
      </div>
    );
  };

  renderSingleContent = (item: string) => {
    const content = [];
    const { t } = this.props;
    for (let i = 0; i < EULA_ITEMS[item]; i += 1) {
      const eulaTile = `${item}${i + 1}`;
      if (EULA_HREF[eulaTile]) {
        let data = t(`eulaContent.${eulaTile}`);
        const key = Object.keys(EULA_HREF[`${eulaTile}`])[0];
        data = data.replace(key, '');
        content.push(
          <div>
            <div className={Styles['il-eula-content-sub-content']}>{data}
              <a href={EULA_HREF[eulaTile][key]}>{t(`eulahref.${eulaTile}`)}</a>.
            </div>
          </div>
        );
      } else {
        content.push(
          <div className={Styles['il-eula-content-sub-content']}>{t(`eulaContent.${item}${i + 1}`)}</div>
        );
      }
    }
    return content;
  };

  renderContent = () => {
    const { t } = this.props;
    return (
      <div>
        {t('eula.content')}
        {Object.keys(EULA_ITEMS).map((item, val) => (
          <div>
            <div className={Styles['il-eula-content-tiles']}>{val + 1}. {t(`eulaTitle.${item}`)}</div>
            {this.renderSingleContent(item)}
          </div>
        ))}
      </div>
    );
  };

  renderSetupProfile = () => {
    const { profileSetupDetails, profileSetupDetails: { eulaDeclined, eulaVersion } = {} } = this.props;
    const { eulaStatusUpdated } = this.state;
    return (
      <ProfileSetupComponent
        profileDetails={profileSetupDetails}
        onSave={(profileInfo) => this.onProfileSetup(profileInfo)}
        eulaActivated={(!eulaDeclined && eulaVersion === 1) || eulaStatusUpdated}
      />
    );
  };

  render() {
    const { t, profileSetupDetails: { eulaDeclined, eulaVersion } = {} } = this.props;
    const { hideConfirmModal, btnDisabled, eulaCollapsed } = this.state;
    return (
      <div>
        <div>
          <IlIconInitializer />

          <Wrapper>
            <div className={classNames(Styles['eula-body'], { [Styles['eula-body-collapsed']]: eulaCollapsed })}>
              <div>
                <div className={Styles['eula-header-container']}>
                  <div className={Styles['eula-header']}>
                    {t('eula.title')}
                  </div>
                  <IlButton
                    className={Styles.collapseButton}
                    kind='ghost'
                    role='button'
                    onClick={(): void => { this.setState({ eulaCollapsed: !eulaCollapsed }); }}
                    type='button'
                  >
                    <IlIcon name={eulaCollapsed ? 'chevron-down' : 'chevron-up'} className={Styles.collapseIcon} />
                  </IlButton>
                </div>
                {!eulaCollapsed
                && (
                <div>
                  <div className={Styles['eula-before-block']}>
                    {t('eula.header')}
                  </div>
                  <div className={Styles['eula-agreement-wrapper']}>
                    <div className={Styles['eula-agreement-body']}>
                      {this.renderContent()}
                    </div>
                  </div>
                  {(!eulaDeclined && eulaVersion === 1) ? (
                    <div className={Styles['il-btn-container']}>
                      <div className={Styles.acceptBtn}>
                        <IlButton
                          className={Styles.btn}
                          size='small'
                          kind='primary'
                          onClick={() => this.onAccept(EULA_ACTIONS.ACCEPT)}
                          disabled
                        >{t('eula.accepted')}
                        </IlButton>
                      </div>
                    </div>
                  )
                    : (
                      <div className={Styles['il-btn-container']}>
                        <div className={Styles.declineBtn}>
                          <IlButton
                            className={Styles.btn}
                            size='small'
                            kind='primary'
                            onClick={() => this.onDeclineClicked()}
                            disabled={btnDisabled}
                          >{t('eula.decline')}
                          </IlButton>
                        </div>
                        <div className={Styles.acceptBtn}>
                          <IlButton
                            className={Styles.btn}
                            size='small'
                            kind='primary'
                            onClick={() => this.onAccept(EULA_ACTIONS.ACCEPT)}
                            disabled={btnDisabled}
                          >{t('eula.accept')}
                          </IlButton>
                        </div>
                      </div>
                    )}
                </div>
                )}
              </div>
            </div>
            {this.renderSetupProfile()}
          </Wrapper>
        </div>
        {!hideConfirmModal && this.renderModal()}

      </div>
    );
  }
}
interface IStateProps {
  profileSetupDetails?: Partial<IProfileProperties>;
}
interface IDispatchProps {
  dispatchEulaAccepted: (action: string) => Promise<void>;
  dispatchGetProfile: (fields?: (keyof IProfile)[] | undefined) => void;
  dispatchGetProfileProfileInfo: (fields?: (keyof IProfile)[] | undefined) => void;
  dispatchUpdateProfileSetup: (req: any) => void;

}
const mapStateToProps = (state: RootState): IStateProps => {
  const { profile: { profileSetupDetails } } = state;
  return {
    profileSetupDetails
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): IDispatchProps => ({
  dispatchEulaAccepted: (action): void => dispatch(
    eulaAccepted(action)
  ),
  dispatchGetProfile: (fields): void => dispatch(getProfileForEulaCheck(fields)),
  dispatchGetProfileProfileInfo: (fields): void => dispatch(getProfileForProfileSetup(fields)),
  dispatchUpdateProfileSetup: (req: any): void => dispatch(profileSetup(req))

}) as IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(I18nNamespaces.DEFAULT)(Eula));
