/*
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { I18nNamespaces } from '../../utils/constants.util';
import IconV2, { IconV2SizeEnum } from '../Icon/IconV2';

import Styles from './PasswordStrengthIndicator.scss';

interface IProps extends WithTranslation {
  score: number;
  repeatCharacters: boolean;
}

export const PasswordStrengthIndicator = (props: IProps): JSX.Element => {
  const {
    score = 0, repeatCharacters = false, t
  } = props;

  let messageIcon = 'check-circle';
  let messageIconClass = 'iconFillGreen';
  let message = t('recovery.STRONG_PASSWORD');
  let colorClass = '';
  if (repeatCharacters) {
    colorClass = Styles.red;
    message = t('recovery.REPEATED_CHARACTERS');
    messageIcon = 'close';
    messageIconClass = 'iconFillRed';
  } else if (score <= 2) {
    colorClass = Styles.red;
    message = t('recovery.ENTER_STRONGER_PASSWORD');
    messageIcon = 'close';
    messageIconClass = 'iconFillRed';
  } else if (score === 3) {
    colorClass = Styles.orange;
    message = t('recovery.ACCEPTABLE_PASSWORD');
    messageIcon = 'warning-v1';
    messageIconClass = 'iconFillYellow';
  }

  const tooltip = (
    <span>
      {t('recovery.PASSWORD_HELP_TEXT')}
    </span>
  );

  return (
    <div className={`${Styles.wrapper} ${colorClass}`}>
      <div className={Styles.passwordStrengthContent}>
        <span className={Styles.passwordStrengthLabel}>{t('recovery.PASSWORD_STRENGTH')}</span>
        <IconV2
          name='info'
          className='iconFillPrimary'
          tooltip={{
            body: tooltip
          }}
        />
      </div>
      <div className={Styles.levels}>
        <span className={score >= 1 ? Styles.reached : ''} />
        <span className={score >= 2 ? Styles.reached : ''} />
        <span className={score >= 3 ? Styles.reached : ''} />
        <span className={score >= 4 ? Styles.reached : ''} />
      </div>
      {score > 0 && (
        <div id='il-message-container' className={Styles.messageContainer}>
          <IconV2
            name={messageIcon}
            className={messageIconClass}
            size={IconV2SizeEnum.ExtraSmall}
            tooltip={{
              body: tooltip
            }}
          />
          <span className={Styles.errorMessage}>{message}</span>
        </div>
      )}
    </div>
  );
};

export default withTranslation(I18nNamespaces.PROFILE)(PasswordStrengthIndicator);
