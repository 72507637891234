/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

import React from 'react';
import { CoreApp } from '@intralinks/apps-core';
import {
  gainSightLoaded,
  IlIconInitializer,
  IlToastContainer,
  IlToastNotificationProps, loadGainSight, setGlobalContext
} from 'il-framework-component';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18next from './i18n';
import * as styles from './App.scss';
import { AppDispatch, RootState } from './app/store';
import { setCoreAppState } from './features/ErrorPages/errorPageSlice';
import RouteComponent from './app/shared/components/RouterComponent/RouteComponent';
import ToastComponent from './app/shared/components/Toast/ToastComponent';
import { showToast } from './app/shared/AppSlice/AppSlice';
import { I18nNamespaces } from './app/shared/utils/constants.util';
import { getConfig } from './app/shared/utils/config.util';

declare const BUILD_ID: string | undefined;

interface IStateProps {
    hideHeaderLogo?: boolean;
    hideFooterLogo?: boolean;
    hideAppName?: boolean;
    toastDetails: IlToastNotificationProps;
    lang: string;
    displayHeader?: boolean;
    displayFooter?: boolean;
}

interface IDispatchProps {
    dispatchGetOrgs: () => void;
    dispatchToast: (toastParams: IlToastNotificationProps) => void;
}

interface IAppProps extends IStateProps, IDispatchProps, WithTranslation {
    appName: string;
    appId: string;
    profileAppName: string;
}

interface IAppState {
  appTitle: string | undefined;
}
const GAINSIGHT_SET_CONTEXT_TIMEOUT = 300;

class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      appTitle: undefined
    };
  }

  componentDidUpdate(prevProps: IAppProps): void {
    const { lang: currentLang } = this.props;
    const { lang: prevLang } = prevProps;

    if (prevLang !== currentLang) {
      i18next.changeLanguage(currentLang);
    }
  }

    renderApp = (): JSX.Element => (
      <>
        <IlIconInitializer />
        <RouteComponent />
        <IlToastContainer position='top-center' />
      </>
    );

    onCloseButtonClick = () => {
      const { dispatchToast } = this.props;
      dispatchToast({
        title: '', timeout: 1, kind: 'error', subtitle: ''
      });
    };

  profileLoaded = async (profile: any): Promise<void> => {
    const {
      gainsightKey, appId, gainsightAnalyticsUrl
    } = getConfig();
    const {
      id, emails, orgId, name: { firstName = '', lastName = '' } = {}
    } = profile;
    const accountDetails = orgId ? { id: orgId } : {};
    loadGainSight(
      gainsightKey,
      {
        id, email: emails[0]?.emailAddress, firstName, lastName
      }, accountDetails, `${gainsightAnalyticsUrl}?v=${BUILD_ID}`
    );
    if (localStorage.getItem('gainSightTimeout') === 'true') {
      const globalContext = { appId };
      // we should remove this timeout once we have a callback or await on the loadGainsight method
      setTimeout(() => setGlobalContext(globalContext), GAINSIGHT_SET_CONTEXT_TIMEOUT);
    } else {
      window.addEventListener(gainSightLoaded, () => {
        const globalContext = { appId };
        setGlobalContext(globalContext);
      });
    }
  };

  render(): JSX.Element {
    const {
      appName, appId, hideHeaderLogo, hideFooterLogo, hideAppName, toastDetails, profileAppName, t,
      displayFooter, displayHeader
    } = this.props;
    const { appTitle } = this.state;

    const path = window.location.pathname.split('/')[2];

    const defaultAppTitle = path === 'profile' ? profileAppName : appName;

    return (
      <CoreApp
        app={{ name: appName, id: appId, profileCustomFields: ['orgId'] }}
        header={{
          title: {
            isVisible: !hideAppName,
            name: appTitle || t(defaultAppTitle, { ns: I18nNamespaces.PROFILE }),
            canClickRedirectHome: false
          },
          logo: { isVisible: !hideHeaderLogo },
          menu: {
            profile: {
              showAccountProfile: true,
              onProfileCallBack: (profile: any): Promise<void> => this.profileLoaded(profile)
            }
          }
        }}
        footer={{ logo: { isVisible: !hideFooterLogo } }}
        showHeader={displayHeader}
        showFooter={displayFooter}
      >
        <div className={styles.toastComponentCls}>
          {
              toastDetails.timeout === 0
                ? <ToastComponent {...toastDetails} onCloseButtonClick={this.onCloseButtonClick} /> : ''
          }
        </div>
        {this.renderApp()}
        <div className={styles.app}>
          <div className={styles['app-header']} />
        </div>
      </CoreApp>
    );
  }
}

const mapStateToProps = (state: RootState): IStateProps => {
  const {
    app: {
      app: {
        hideFooterLogo, hideHeaderLogo, hideAppName, displayFooter, displayHeader
      }
    }
  } = state;
  const { appState: { toastDetails }, coreApp: { lang } } = state;

  return {
    hideFooterLogo,
    hideHeaderLogo,
    hideAppName,
    toastDetails,
    lang,
    displayHeader,
    displayFooter
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): IDispatchProps => ({
  dispatchGetOrgs: (): void => dispatch(setCoreAppState()),
  dispatchToast: (toastParams: IlToastNotificationProps): void => dispatch(showToast(toastParams))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation([I18nNamespaces.PROFILE])(App));
