import React from 'react';

import * as styles from './CopyIcon.scss';

interface IconProps {
  className?: string;
}

const CopyIcon: React.FC<IconProps> = (props: IconProps): JSX.Element => {
  const { className: classes } = props;
  return (
    <svg
      className={classes}
      width='100%'
      viewBox='0 0 32 32'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>3FB15EAE-8D63-45EF-BA20-DFCF0C3291AC</title>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Icons/Copy' fill='#3C3C3C'>
          <path
            className={styles.fill}
            d='M3,6 C3.51283584,6 3.93550716,6.38604019 3.99327227,6.88337887 L4,7 L4,29 C4,
            29.5128358 4.38604019,29.9355072 4.88337887,29.9932723 L5,30 L23,30 C23.5522847,30 24,
            30.4477153 24,31 C24,31.5128358 23.6139598,31.9355072 23.1166211,31.9932723 L23,32 L5,
            32 C3.40231912,32 2.09633912,30.75108 2.00509269,29.1762728 L2,29 L2,7 C2,6.44771525 2.44771525,
            6 3,6 Z M28,1.04805054e-13 C29.1045695,1.04805054e-13 30,0.8954305 30,2 L30,2 L30,26 C30,
            27.1045695 29.1045695,28 28,28 L28,28 L8,28 C6.8954305,28 6,27.1045695 6,26 L6,26 L6,2 C6,0.8954305 6.8954305,
            1.04805054e-13 8,1.04805054e-13 L8,1.04805054e-13 Z M28,2 L8,2 L8,26 L28,26 L28,2 Z'
            id='Icon'
            transform='translate(16.000000, 16.000000) scale(-1, 1) rotate(-180.000000) translate(-16.000000, -16.000000) '
          />
        </g>
      </g>
    </svg>
  );
};

CopyIcon.defaultProps = {
  className: undefined
};

export default CopyIcon;
