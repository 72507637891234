/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

import { RequestsHandlerInstance } from '../utils/RequestsHandler';
import IBaseModel from '../../../domain/models/IBaseModel';
import { RequestConfig } from '../utils/RequestConfig';
import { IRepositoryConfig, IPathParams } from '../IRepository';
import { composeQueryParams } from '../utils/RequestAdaptor';
import { IGetListQueryParams } from '../utils/IGetListQueryParams';
import { IResourceBaseDto } from '../../dto/IResourceBaseDto';

export default class BaseRepo<M extends IBaseModel, D extends IResourceBaseDto, P extends IPathParams> {
  constructor(
    public requestsHandler: RequestsHandlerInstance,
    protected config: IRepositoryConfig<M, D, P>
  ) {
    // do nothing.
  }

  protected getResourcePath(pathParams?: P): string {
    if (typeof this.config.rootResourcePath === 'function') {
      return this.config.rootResourcePath(pathParams);
    }
    return this.config.rootResourcePath;
  }

  protected getSingleResourcePath(id: string, pathParams?: P): string {
    if (typeof this.config.rootResourcePath === 'function') {
      return this.config.rootResourcePath(pathParams);
    }
    return `${this.getResourcePath(pathParams)}/${id}`;
  }

  protected getAdaptedRequestConfig<Q extends IGetListQueryParams<D>>(queryParams?: Q): RequestConfig | undefined {
    const customConfig: RequestConfig | undefined = queryParams ? {
      params: composeQueryParams<D, Q>(queryParams)
    } : {};
    if (this.config.customBaseUrl) {
      customConfig.baseURL = this.config.customBaseUrl;
    }
    return customConfig;
  }

  protected adaptResponseFromDtoToDomain(dto: D): Partial<M> {
    return this.config.adaptor?.fromDto ? this.config.adaptor.fromDto(dto) : {};
  }

  protected adaptDomainToRequestDto(domain: Partial<M>): Partial<D> {
    return this.config.adaptor.toDto ? this.config.adaptor.toDto(domain) : {};
  }
}
