/*
 * Copyright 2022, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable class-methods-use-this */
import { IAdaptor } from '../utils/IAdaptor';
import { IProfileApps } from '../../../domain/models/IProfileApps';
import ProfileAppsDto from '../../dto/ProfileAppsDto';

export default class ProfileAdaptor implements IAdaptor<IProfileApps, ProfileAppsDto> {
    /**
     * This property is used to map properties from FE to properties in BE, such that when requesting some fields,
     * or filtering by some fields, the infrastructure layer knows what to request from the BE.
     * */
    domainToDtoPropertiesMap: { [K in keyof IProfileApps]?: keyof ProfileAppsDto } = {
      id: 'id',
      name: 'name',
      description: 'description',
      url: 'url',
      displayText: 'displayText'
    };

    /**
     * This is used mostly when we do get calls, so that data from BE is properly mapped to data in FE
     */
    fromDto(dto: ProfileAppsDto): Partial<IProfileApps> {
      return {
        id: dto.id,
        description: dto.description,
        url: dto.url,
        name: dto.name,
        displayText: dto.displayText
      };
    }

    /**
     * This function is used to map FE Domain models to BE models.
     * It will be called when we send a domain model to the infrastructure, and it needs to send a Dto to BE (post, patch, put).
     * Bear in mind that you can just write the properties that you use,
     * no need to add the properties that you don't need to send over the network.
     */
    toDto(profile: Partial<IProfileApps>): Partial<ProfileAppsDto> {
      return {
        name: profile.name,
        description: profile.description,
        id: profile.id,
        url: profile.url,
        displayText: profile.displayText
      };
    }
}
