/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

import { IBaseListModel } from '../../dto/IBaseListModel';

export interface IListApiResponse<T> {
  data: {
    data: T[];
    _meta?: {
      stats?: {
        count: number;
        limit: number;
        offset: number;
      };
    };
    _status: {
      code: number;
    };
  };
}

export interface ISingleApiResponse<T> {
  data: {
    data: T;
    _status: {
      code: number;
    };
  };
}

const processResponse = <T>(response: IListApiResponse<T>): T[] => response.data.data;
export const getResponseAsSingle = <T>(result: ISingleApiResponse<T>): T | undefined => result.data?.data || undefined;

// eslint-disable-next-line no-underscore-dangle
const extractTotalCountFromResponse = <T>(response: IListApiResponse<T>): number => response.data._meta?.stats?.count || -1;

export const getResponseAsList = <Z>(result: IListApiResponse<Z>): IBaseListModel<Z> => {
  const processedResponse = processResponse(result);
  const totalItems = extractTotalCountFromResponse(result);
  return {
    totalItems,
    items: processedResponse
  };
};
