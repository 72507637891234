/* *
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 * */

// eslint-disable-next-line import/prefer-default-export
import { AxiosError } from 'axios';
import { compose, first, prop } from 'lodash/fp';

// all Api calls error codes : Locales for each error from error.json
export const ErrorCodes: { [key: string]: string } = {
  WRONG_PASSWORD: 'authentication.errorMessages.WRONG_PASSWORD',
  UNSUPPORTED_USER_STATUS: 'authentication.errorMessages.UNSUPPORTED_USER_STATUS',
  PASSWORD_USED_TOO_RECENTLY: 'authentication.errorMessages.PASSWORD_USED_TOO_RECENTLY',
  NEW_PASSWORD_SAME_AS_OLD: 'authentication.errorMessages.NEW_PASSWORD_SAME_AS_OLD',
  PASSWORD_CRITERIA_NOT_MET: 'authentication.errorMessages.PASSWORD_CRITERIA_NOT_MET',
  COMMON_PASSWORD: 'authentication.errorMessages.COMMON_PASSWORD',
  FACTOR_NOT_FOUND: 'authentication.errorMessages.FACTOR_NOT_FOUND',
  UNABLE_TO_DELETE_FACTOR: 'authentication.errorMessages.UNABLE_TO_DELETE_FACTOR',
  EXPIRED_TOKEN: 'defaultErrorMessage',
  INVALID_PHONE_NUMBER: 'authentication.errorMessages.INVALID_PHONE_NUMBER',
  PHONE_NUMBER_REQUIRED: 'authentication.errorMessages.PHONE_NUMBER_REQUIRED',
  INVALID_PASSCODE_OR_ANSWER: 'authentication.errorMessages.INVALID_PASSCODE_OR_ANSWER',
  USER_NOT_FOUND: 'authentication.errorMessages.USER_NOT_FOUND'
};

// Error codes to compare and display validation error messages
export enum ErrorEnumCodes {
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  ExpiredJwt = 'EXPIRED_TOKEN',
  PhoneRequired = 'PHONE_NUMBER_REQUIRED',
  InvalidPassCode='INVALID_PASSCODE_OR_ANSWER'
}

export const getErrorResponse = (error: AxiosError): string => compose(
  prop('code'),
  first,
  prop(['response', 'data', '_status', 'errors'])
)(error);

export const getErrorStatusCode = (error: AxiosError): string => compose(
  prop('status'),
  prop(['response', 'data', '_status'])
)(error);

export const getErrorCode = (error: AxiosError): string => {
  const errorCode = getErrorResponse(error);
  const errorKey = errorCode && errorCode.split(':')[1];
  return errorKey;
};

export const getTranslatedErrorKey = (error: AxiosError): string => {
  const errorCode = getErrorCode(error);
  const translationKey = ErrorCodes[errorCode] ? ErrorCodes[errorCode] : 'defaultErrorMessage';
  return translationKey;
};
