/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import * as React from 'react';
import { IlTooltipTippy, IlTooltipTippyProps } from 'il-framework-component';

class Tooltip extends React.Component<IlTooltipTippyProps> {
  render() {
    const { children, ...rest } = this.props;
    return (
      <IlTooltipTippy
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </IlTooltipTippy>
    );
  }
}

export default Tooltip;
