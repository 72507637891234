/**
 * Copyright 2019, IntraLinks, Inc. All rights reserved.
 */

import * as React from 'react';
import {
  IlToastNotification,
  IlToastNotificationProps,
  IlIconInitializer
} from 'il-framework-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { I18nNamespaces } from '../../utils/constants.util';

interface IToastComponentProps extends IlToastNotificationProps, WithTranslation {
}

class ToastComponent extends React.Component<IToastComponentProps> {
  constructor(props: IToastComponentProps) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const {
      timeout, kind, onCloseButtonClick, subtitle, title
    } = this.props;
    const titleData = t(`${title}`);
    return (
      <>
        <IlIconInitializer />
        <IlToastNotification
          title={titleData}
          timeout={timeout}
          caption=''
          kind={kind}
          onCloseButtonClick={onCloseButtonClick}
          subtitle={subtitle}
        />
      </>
    );
  }
}

export default withTranslation(I18nNamespaces.ADMIN)(ToastComponent);
