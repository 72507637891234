/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable class-methods-use-this */
import * as httpStatus from 'http-status';
import { IGetListQueryParams } from '../utils/IGetListQueryParams';
import BaseRepo from './BaseRepo';
import {
  getResponseAsSingle,
  getResponseAsList
} from '../utils/ResponseProcessor';
import IBaseModel from '../../../domain/models/IBaseModel';
import { IResourceBaseDto } from '../../dto/IResourceBaseDto';
import IRepository, { IPathParams } from '../IRepository';
import { adaptQueryParamsToDto } from '../utils/RequestAdaptor';
import { IBaseListModel } from '../../dto/IBaseListModel';

export default class Repository<
  M extends IBaseModel,
  D extends IResourceBaseDto,
  P extends IPathParams
> extends BaseRepo<M, D, P> implements IRepository<M, D, P> {
  async getById<Q extends IGetListQueryParams<M>>(
    id: string,
    pathParams: P | undefined = undefined,
    queryParams: Q | undefined = undefined
  ): Promise<M | undefined> {
    const { adaptor } = this.config;
    const path = this.getSingleResourcePath(id, pathParams);
    const requestConfig = this.getAdaptedRequestConfig(queryParams ? adaptQueryParamsToDto(queryParams, adaptor) : {});
    const response = await this.requestsHandler.get(path, requestConfig);
    const responseAsDto = getResponseAsSingle<D>(response);
    return responseAsDto
      ? (this.adaptResponseFromDtoToDomain(responseAsDto) as M)
      : undefined;
  }

  async get<Q extends IGetListQueryParams<M>>(
    pathParams: P | undefined = undefined,
    queryParams: Q | undefined = undefined
  ): Promise<M | undefined> {
    const { adaptor } = this.config;
    const path = this.getResourcePath(pathParams);
    const requestConfig = this.getAdaptedRequestConfig(queryParams ? adaptQueryParamsToDto(queryParams, adaptor) : {});
    const response = await this.requestsHandler.get(path, requestConfig);
    const responseAsDto = getResponseAsSingle<D>(response);
    return responseAsDto
      ? (this.adaptResponseFromDtoToDomain(responseAsDto) as M)
      : undefined;
  }

  async getAll<Q extends IGetListQueryParams<M>>(
    queryParams: Q | undefined = undefined,
    pathParams: P | undefined = undefined
  ): Promise<IBaseListModel<M>> {
    const { adaptor } = this.config;
    const path = this.getResourcePath(pathParams);
    const requestConfig = this.getAdaptedRequestConfig(queryParams ? adaptQueryParamsToDto(queryParams, adaptor) : {});
    const response = await this.requestsHandler.get(path, requestConfig);
    const responseAsDto = getResponseAsList<D>(response);
    const items = responseAsDto.items.map(
      (result: D): M => this.adaptResponseFromDtoToDomain(result) as M
    );
    return {
      totalItems: responseAsDto.totalItems,
      items
    };
  }

  async delete(
    id: string,
    pathParams: P | undefined = undefined
  ): Promise<boolean> {
    const path = this.getSingleResourcePath(id, pathParams);
    const requestConfig = this.getAdaptedRequestConfig();
    const response = await this.requestsHandler.delete(path, requestConfig);
    return (
      response.status === httpStatus.OK
      || response.status === httpStatus.ACCEPTED
      || response.status === httpStatus.NO_CONTENT
    );
  }

  async create(
    requestDomain: Partial<M>,
    pathParams: P | undefined = undefined
  ): Promise<M | undefined> {
    const path = this.getResourcePath(pathParams);
    const requestConfig = this.getAdaptedRequestConfig();
    const response = await this.requestsHandler.post(
      path,
      {
        data: this.adaptDomainToRequestDto(requestDomain)
      },
      requestConfig
    );
    const responseAsDto = getResponseAsSingle<D>(response);
    return responseAsDto
      ? (this.adaptResponseFromDtoToDomain(responseAsDto) as M)
      : undefined;
  }

  async update<T extends Partial<M>>(
    requestData: T,
    pathParams: P | undefined = undefined
  ): Promise<M> {
    const path = this.getSingleResourcePath(requestData.id!, pathParams);
    const requestConfig = this.getAdaptedRequestConfig();
    const requestDataUpdate = requestData;
    delete requestDataUpdate.id;
    const response = await this.requestsHandler.patch(
      path, {
        data: this.adaptDomainToRequestDto(requestDataUpdate)
      },
      requestConfig
    );
    const responseAsDto = getResponseAsSingle<D>(response);
    return this.adaptResponseFromDtoToDomain(responseAsDto!) as M; // this should throw error if responseAsDto is undefined
  }

  async replace<T extends Partial<M>>(
    requestData: T,
    pathParams: P | undefined = undefined
  ): Promise<M> {
    const path = this.getSingleResourcePath(requestData.id!, pathParams);
    const requestConfig = this.getAdaptedRequestConfig();
    const requestDataUpdate = requestData;
    delete requestDataUpdate.id;
    const response = await this.requestsHandler.put(
      path, {
        data: this.adaptDomainToRequestDto(requestDataUpdate)
      },
      requestConfig
    );
    const responseAsDto = getResponseAsSingle<D>(response);
    return this.adaptResponseFromDtoToDomain(responseAsDto!) as M; // this should throw error if responseAsDto is undefined
  }

  async createWithRedirect<T extends Partial<M>>(
    requestData: T,
    pathParams: P | undefined = undefined
  ): Promise<any> {
    const path = this.getResourcePath(pathParams);
    const requestConfig = this.getAdaptedRequestConfig();
    const requestDataUpdate = requestData;
    delete requestDataUpdate.id;
    const config = Object.assign(requestConfig, {
      headers: {
        Accept: 'text/html,application/json'
      }
    });
    const response = await this.requestsHandler.post(
      path, {
        data: this.adaptDomainToRequestDto(requestDataUpdate)
      },
      config
    );
    return response;
  }
}
