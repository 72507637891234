/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import { IRecoveryRepository } from '../IRecoveryRepository';
import { RequestsHandlerInstance } from '../utils/RequestsHandler';

export default class RecoveryRepository implements IRecoveryRepository {
  constructor(public requestsHandler: RequestsHandlerInstance, public rootResourcePath: string, public authBaseUrl: string) {}

  resetPassword(oldPassword: string, newPassword: string): Promise<any> {
    return this.requestsHandler.put(
      `${this.authBaseUrl}/${this.rootResourcePath}/password`,
      {
        data: {
          oldPassword,
          newPassword
        }
      }
    );
  }
}
