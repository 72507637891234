/*
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
// eslint-disable-next-line import/prefer-default-export
export const PHONE_TYPES: { [key: string]: string } = {
  HOME: 'Home',
  WORK: 'Work',
  MOBILE: 'Mobile',
  FAX: 'Fax'
};
export const DEFAULT_PROVIDER_ID = '18ef2c83-bf32-4693-a986-f8f2ac51d895';

export const LANGUAGE_TYPES: { [key: string]: string } = {
  EN_US: 'English (US)',
  JA_JP: '日本語',
  EN_GB: 'English (International)',
  FR_FR: 'Français',
  DE_DE: 'Deutsch',
  PT_BR: 'Português',
  ES_ES: 'Español',
  ZH_CN: '简体中文',
  RU_RU: 'Русский',
  KO_KR: '한국어',
  IT_IT: 'Italiano',
  NL_NL: 'Nederlands',
  SV_SE: 'Svenska',
  TR_TR: 'Türkçe',
  EL_GR: 'Ελληνικά'
};

export const TIMEZONES: { [key: string]: string } = {
  GMTMinus12: 'GMT-12',
  NT: 'GMT-11 NT',
  AHST: 'GMT-10 AHST',
  YST: 'GMT-09 YST',
  PST: 'GMT-08 PST',
  MST: 'GMT-07 MST',
  CST: 'GMT-06 CST',
  EST: 'GMT-05 EST',
  AST: 'GMT-04 AST, EDT',
  BuenosAires: 'GMT-03 Buenos Aires',
  AT: 'GMT-02 AT',
  WAT: 'GMT-01 WAT',
  GMT: 'GMT0',
  CET: 'GMT+01 CET',
  EET: 'GMT+02 EET',
  BT: 'GMT+03 BT',
  ZP4: 'GMT+04 ZP4',
  ZP5: 'GMT+05 ZP5',
  ZP6: 'GMT+06 ZP6',
  WAST: 'GMT+07 WAST',
  CCT: 'GMT+08 CCT',
  JST: 'GMT+09 JST',
  ACST: 'GMT+9.5 ACST',
  GST: 'GMT+10 GST',
  ACDT: 'GMT+10.5 ACDT',
  GMTPlus11: 'GMT+11',
  NZST: 'GMT+12 NZST',
  NZST2: 'GMT+13 NZST',
  IST: 'GMT+5.5 IST',
  Brasilia: 'GMT-03 Brasilia',
  BrasiliaDST: 'GMT-02 Brasilia DST',
  Santiago: 'GMT-04 Santiago',
  SantiagoDST: 'GMT-03 Santiago DST',
  Bogota: 'GMT-05 Bogota',
  MexicoCity: 'GMT-06 Mexico City',
  MexicoCityDST: 'GMT-05 Mexico City DST'
};

export const USER_TITLES: { [key: string]: string } = {
  UNAVAILABLE: 'Not Applicable',
  ANALYST: 'Analyst',
  ASSISTANT_SUPPORT_STAFF: 'Assistant / Support Staff',
  ASSOCIATE: 'Associate',
  BOARD_MEMBER: 'Board Member',
  CHAIRMAN_PRESIDENT_CEO: 'Chairman / President / CEO',
  C_LEVEL_OTHER: 'C-Level (Other)',
  COMPLIANCE_OFFICER: 'Compliance Officer',
  CONTROLLER_TREASURER: 'Controller / Treasurer',
  DOCTOR_PHYSICIAN: 'Doctor / Physician',
  ENGINEER_RESEARCHER_SCIENTIST: 'Engineer / Researcher / Scientist',
  GC_OTHER_COUNSEL: 'GC / Other Counsel',
  GENERAL_MANAGER: 'General Manager',
  MANAGER_SUPERVISOR: 'Manager / Supervisor',
  MANAGING_DIRECTOR_DIRECTOR: 'Managing Director / Director',
  OTHER: 'Other',
  PARTNER: 'Partner',
  PROJECT_MANAGER: 'Project Manager',
  TRADER: 'Trader',
  VP_SVP_EVP: 'VP / SVP / EVP'
};

export const USER_INDUSTRIES: { [key: string]: string } = {
  UNAVAILABLE: 'Not Applicable',
  ADVERTISING_MARKETING_PR: 'Advertising/Marketing PR',
  AEROSPACE: 'Aerospace',
  AGRICULTURE_CHEMICALS_FOREST_PRODUCTS: 'Agriculture/Chemicals/Forest Products',
  AUTOMOTIVE: 'Automotice',
  BUSINESS_PROFESSIONAL_SERVICES: 'Professional Services',
  CONSUMER_GOODS: 'Consumer Goods',
  EDUCATION: 'Education',
  ENERGY_UTILITIES: 'Energy Utilities',
  FINANCIAL_SERVICES: 'Financial Services',
  GOVERNMENT_MILITARY_PUBLIC_SERVICE: 'Government/Military/Public',
  HEALTHCARE_MEDICAL: 'Healthcare',
  HOSPITALITY_RECREATION: 'Hospitality/Recreation',
  INSURANCE: 'Insurance',
  MANUFACTURING_CONSTRUCTION_INDUSTRIALS: 'Manufacturing/Construction/Industrial',
  MEDIA_PUBLISHING_ENTERTAINMENT: 'Media/Publishing/Entertainment',
  METALS_MINING: 'Metals/Mining',
  OTHER: 'Other',
  PHARMACEUTICALS_BIOTECHNOLOGY: 'Pharmaceuticals/Biotechnology',
  REAL_ESTATE: 'Real Estate',
  RETAIL: 'Retails',
  TECHNOLOGY_COMPUTERS_ELECTRONICS: 'Technology/Computers/Electronics',
  TELECOMMUNICATIONS_NETWORKING: 'Telecommunications/Networking',
  TRAVEL_TRANSPORTATION: 'Travel/Transportation',
  SUSTAINABILITY: 'Sustainability'
};
