/*
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

import { RequestsHandlerInstance } from '../utils/RequestsHandler';
import { CSRF_HEADER_NAME } from '../../../app/shared/utils/constants.util';

const SESSION_SUFFIX = '/session';

export default class GatewayRepository {
  gatewaySessionURL: string;

  requestHandler: RequestsHandlerInstance;

  constructor(requestHandler: RequestsHandlerInstance, gatewayBaseUrl: string) {
    this.requestHandler = requestHandler;
    this.gatewaySessionURL = `${gatewayBaseUrl}${SESSION_SUFFIX}`;
  }

  async getSessionCSRFToken(): Promise<string> {
    const response = await this.requestHandler.get(this.gatewaySessionURL);

    return response.headers[CSRF_HEADER_NAME];
  }
}
