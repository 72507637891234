/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import Repos from '../../infrastructure/repositories/Repos';
import { IConsentScopesProperties } from '../../domain/models/IConsentScopes';
import { getErrorStatusCode } from '../../app/shared/utils/errors.util';

export interface IConsentState {
  scopes: IConsentScopesProperties[];
}

const initialState: IConsentState = {
  scopes: []
};

export const consentSlice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    getConsentScopes: (state: IConsentState, action: PayloadAction<{ items: IConsentScopesProperties[] }>): void => {
      state.scopes = action.payload.items;
    }
  }
});

export const {
  getConsentScopes
} = consentSlice.actions;

export const consentAccepted = (action: string, scopes: string[]): AppThunk => async (): Promise<void> => {
  await Repos.instance.consentRepository.createWithRedirect({ action, scopes }, undefined);
};

export const consentDeclined = (action: string): AppThunk => async (): Promise<void> => {
  await Repos.instance.consentRepository.createWithRedirect({ action }, undefined);
};

export const consentScopes = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    const scopes = await Repos.instance.consentScopesRepository.getAll(undefined, undefined);
    dispatch(getConsentScopes({ items: scopes.items }));
  } catch (error) {
    const errorStatusCode = error?.response?.data?._status?.status;
    const errorCode = getErrorStatusCode(errorStatusCode) || '500';
    throw errorCode;
  }
};

export default consentSlice.reducer;
