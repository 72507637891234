/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

import {
  configureStore, ThunkAction, Action, getDefaultMiddleware, combineReducers
} from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import { coreAppReducer } from '@intralinks/apps-core';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';

import profileReducer from '../features/Profile/profileSlice';

import hostAppReducer from '../features/ErrorPages/errorPageSlice';
import enrollReducer from '../features/Profile/EnrollMFA/enrollSlice';
import { ENDPOINT_PREFIX } from './shared/utils/constants.util';

import consentReducer from '../features/Consent/ConsentSlice';
import appSlice from './shared/AppSlice/AppSlice';

import updatePasswordReducer from '../features/Profile/UpdatePassword/updatePasswordSlice';

export const routerHistory = createBrowserHistory({
  basename: ENDPOINT_PREFIX
});

const rootReducer = combineReducers({
  router: connectRouter(routerHistory),
  coreApp: coreAppReducer,
  // add more reducers
  profile: profileReducer,

  app: hostAppReducer,

  appState: appSlice,

  enroll: enrollReducer,
  updatePassword: updatePasswordReducer,
  consent: consentReducer
});

const middlewares = getDefaultMiddleware({
  serializableCheck: false
});
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}
middlewares.push(routerMiddleware(routerHistory));

export const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
  devTools: true
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
