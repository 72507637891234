/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

const ROUTES = {
  HOME: '/',
  PROFILE_CONTAINER: '/profile/info',
  RECOVERY_SETTINGS_CONTAINER: '/profile/recoverysettings',
  UPDATE_PASSWORD_CONTAINER: '/profile/passwords',
  EXTERNAL_APP_ACCESS_CONTAINER: '/profile/externalappaccess'
};

export default ROUTES;
