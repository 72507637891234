/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

export type filterType = string | string[] | object[] | IInQueryParam | IRegexQueryParam | object;

export enum SortCriteria {
  Ascending = 'asc',
  Descending = 'dsc',
  None = ''
}

export interface IComparison<V> {
  // greater than
  GT?: V;
  // greater than or equal
  GTE?: V;
  // less than or equal
  LTE?: V;
  // less than
  LT?: V;
  // equals
  EQ?: V;
  // not equals
  NE?: V;
}

export interface ISort {
  column: string;
  direction: SortCriteria;
}

export interface IInQueryParam {
  IN: string[];
}

export interface IRegexQueryParam {
  REGEX: string;
}

export type IBooleanFilterType<M> = {
  [T in keyof M]?: IComparison<M[T]> | INegativeComparison<M[T]> | IInclusion<M[T]>
};

export interface INegativeComparison<V> {
  NOT: IComparison<V>;
}

export interface IInclusion<V> {
  // in
  IN?: V[];
  // not in
  NIN?: V[];
}

export type LogicalFilterValueType<M> = Array<IQueryFilterType<M>>;

export interface OrType<M> {
  OR: LogicalFilterValueType<M>;
}

export interface AndType<M> {
  AND: LogicalFilterValueType<M>;
}

export interface RegexType {
  REGEX: string;
}
// optional simple filtering: defines one or more fields and values to match with a full equivalency
// eg: ?filter={fieldName=value}
export type ComplexQueryType<M> = AndType<M> | OrType<M> | RegexType | IBooleanFilterType<M>;
export type IQueryFilterType<M> = Partial<M> | ComplexQueryType<M> | {[key in keyof M ]?: ComplexQueryType<M>};

export interface IGetListQueryParams<T> {
  sort?: ISort[];
  filter?: IQueryFilterType<T>;
  // optional arrays of properties names to be used as comma-delimited list of resource fields; only these fields will be returned
  // eg.: ?fields=name,description
  fields?: (keyof T)[];
  limit?: number;
  offset?: number;
}
