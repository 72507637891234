/*
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import * as React from 'react';
import { IlIcon, IlTippyPlacement } from 'il-framework-component';
import classNames from 'classnames';

import Tooltip from '../Tooltip/Tooltip';

import * as styles from './IconV2.scss';

export enum IconV2SizeEnum {
  Small = 'small',
  ExtraSmall = 'extraSmall',
  Medium = 'medium'
}

export interface IconV2Props {
  name: string;
  className?: string;
  size?: IconV2SizeEnum;
}

export interface IIconV2ComponentProps extends IconV2Props{
  tooltip?: {
    body: React.ReactChild;
    appendTo?: Element;
    className?: string;
    placement?: IlTippyPlacement;
  };
}

class IconV2 extends React.Component<IIconV2ComponentProps> {
  renderIcon(): JSX.Element {
    const {
      name, className = '', size = IconV2SizeEnum.Small
    } = this.props;
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      <span className={styles.iconHolder}>
        <IlIcon
          name={name}
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          className={classNames(styles[`${className}Color`],
            { [styles.smallSize]: size === IconV2SizeEnum.Small },
            { [styles.extraSmallSize]: size === IconV2SizeEnum.ExtraSmall },
            { [styles.mediumSize]: size === IconV2SizeEnum.Medium })}
        />
      </span>
    );
  }

  renderIconWithTooltip = (): JSX.Element => {
    const {
      tooltip: {
        body = '', placement, appendTo = document.body, className = ''
      } = {}
    } = this.props;

    return (
      <Tooltip
        className={className}
        content={body}
        appendTo={appendTo}
        placement={placement}
      >
        {this.renderIcon()}
      </Tooltip>
    );
  };

  render(): JSX.Element {
    const { tooltip } = this.props;

    return tooltip
      ? this.renderIconWithTooltip()
      : this.renderIcon();
  }
}
export default IconV2;
