/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosError } from 'axios';

import { IProfile } from '../../domain/models/IProfile';
import { IMFARepository } from './IMFARepository';
import { IProfileExternalApps } from '../../domain/models/IProfileExternalApps';
import MFARepository from './impl/MFARepository';
import { IRecoveryRepository } from './IRecoveryRepository';
import RecoveryRepository from './impl/RecoveryRepository';

import ProfileDto from '../dto/ProfileDto';
import ProfileAppsDto from '../dto/ProfileAppsDto';
import ProfileExternalAppsDto from '../dto/ProfileExtrenalAppsDto';

import { IEula } from '../../domain/models/IEula';
import EulaDto from '../dto/EulaDto';
import EulaAdaptor from './impl/EulaAdaptor';
import ProfileAdaptor from './impl/ProfileAdaptor';
import ProfileAppsAdaptor from './impl/ProfileAppsAdaptor';
import ProfileExternalAppsAdaptor from './impl/ProfileExternalAppsAdaptor';
import { IProfileApps } from '../../domain/models/IProfileApps';
import { IConsent } from '../../domain/models/IConsent';
import ConsentDto from '../dto/consentDto';
import ConsentAdaptor from './impl/ConsentAdaptor';
import IRepository from './IRepository';
import Repository from './impl/Repository';
import { RequestsHandlerInstance } from './utils/RequestsHandler';
import IConsentScopesDto from '../dto/IConsentScopesDto';
import { IConsentScopes } from '../../domain/models/IConsentScopes';
import ConsentScopesAdaptor from './impl/ConsentScopesAdaptor';
import { IGatewayRepository } from './IGatewayRepository';
import GatewayRepository from './impl/GatewayRepository';
import { CSRF_HEADER_NAME, CSRF_INCLUSION_METHODS } from '../../app/shared/utils/constants.util';

export interface IReposConfigureOptions {
  apiBaseUrl: string;
  requestsHandler?: RequestsHandlerInstance;
  interceptors?: IInterceptors;
  authBaseUrl: string;
  gatewayBaseUrl?: string;
  csrfEnabled?: boolean;
  csrfToken?: string;
}

interface IInterceptors {
  responseInterceptor: {
    onResponseSuccess: <R>(response: R) => R | Promise<R>;
    onResponseError: <T>(error: AxiosError) => Promise<T>;
  };
}

export default class Repos {
  private static readonly defaultBaseUrl = '';

  private static readonly defaultAuthBaseUrl = '';

  private static mInstance: Repos;

  static get instance(): Repos {
    if (!Repos.mInstance) {
      Repos.mInstance = new Repos();
    }
    return Repos.mInstance;
  }

  private requestsHandler: RequestsHandlerInstance | undefined;

  private mEulaRepository: IRepository<IEula, EulaDto, { }> | undefined;

  private mProfileRepository: IRepository<IProfile, ProfileDto, { }> | undefined;

  private mProfileAppsRepository: IRepository<IProfileApps, ProfileAppsDto, { }> | undefined;

  private mProfileExternalAppsRepository: IRepository<IProfileExternalApps, ProfileExternalAppsDto, { }> | undefined;

  private mRecoveryRepository: IRecoveryRepository | undefined;

  private mMFARepository: IMFARepository | undefined;

  private mConsentRepository: IRepository<IConsent, ConsentDto, {}> | undefined;

  get profileRepository(): IRepository<IProfile, ProfileDto, { }> {
    return this.mProfileRepository!;
  }

  get profileAppRepository(): IRepository<IProfileApps, ProfileAppsDto, { }> {
    return this.mProfileAppsRepository!;
  }

  get recoveryRepository(): IRecoveryRepository {
    return this.mRecoveryRepository!;
  }

  get eulRepository(): IRepository<IEula, EulaDto, {}> {
    return this.mEulaRepository!;
  }

  get mfaRepository(): IMFARepository {
    return this.mMFARepository!;
  }

  private mConsetScopesRepository: Repository<IConsentScopes, IConsentScopesDto, {}> | undefined;

  get consentScopesRepository(): IRepository<IConsentScopes, IConsentScopesDto, {}> {
    return this.mConsetScopesRepository!;
  }

  get profileExternalAppsRepository(): IRepository<IProfileExternalApps, ProfileExternalAppsDto, { }> {
    return this.mProfileExternalAppsRepository!;
  }

  get consentRepository(): IRepository<IConsent, ConsentDto, {}> {
    return this.mConsentRepository!;
  }

  private mGatewayRepository: IGatewayRepository | undefined;

  get gatewayRepository(): IGatewayRepository {
    return this.mGatewayRepository!;
  }

  private setCSRFToken(token: string): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const method of CSRF_INCLUSION_METHODS) {
      this.requestsHandler!.defaults.headers[method]![CSRF_HEADER_NAME] = token;
    }
  }

  private async getAndSetCSRFToken(csrfEnabled: boolean, csrfToken?: string): Promise<void> {
    if (csrfEnabled && csrfToken) {
      this.setCSRFToken(csrfToken);
      return;
    }
    try {
      const token = csrfEnabled
        ? await Repos.instance.gatewayRepository.getSessionCSRFToken()
        : 'mockCSRFToken';

      this.setCSRFToken(token);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  async configure(
    options: IReposConfigureOptions = {
      apiBaseUrl: Repos.defaultBaseUrl,
      authBaseUrl: Repos.defaultAuthBaseUrl,
      gatewayBaseUrl: ''
    }
  ): Promise<void> {
    const {
      apiBaseUrl, requestsHandler, interceptors, csrfEnabled, csrfToken
    } = options;
    this.requestsHandler = requestsHandler || axios.create({
      baseURL: apiBaseUrl,
      withCredentials: true
    });

    if (interceptors) {
      const {
        onResponseError,
        onResponseSuccess
      } = interceptors.responseInterceptor;
      this.requestsHandler.interceptors.response.use(
        onResponseSuccess,
        onResponseError
      );
    }

    this.configureRepos(options, this.requestsHandler);
    await this.getAndSetCSRFToken(!!csrfEnabled, csrfToken);
  }

  private configureRepos(
    options: IReposConfigureOptions,
    requestsHandler: RequestsHandlerInstance
  ): void {
    this.mGatewayRepository = new GatewayRepository(requestsHandler, options.gatewayBaseUrl!);
    this.mEulaRepository = new Repository<IEula, EulaDto, {}>(requestsHandler, {
      rootResourcePath: 'eula/activate',
      adaptor: new EulaAdaptor()
    });
    this.mProfileRepository = new Repository<IProfile, ProfileDto, { }>(requestsHandler, {
      rootResourcePath: 'profile?realIdentity=true',
      adaptor: new ProfileAdaptor()
    });
    this.mProfileAppsRepository = new Repository<IProfileApps, ProfileAppsDto, { }>(requestsHandler, {
      rootResourcePath: 'profile/applications',
      adaptor: new ProfileAppsAdaptor()
    });
    this.mMFARepository = new MFARepository(requestsHandler, 'profile', options.authBaseUrl);
    this.mRecoveryRepository = new RecoveryRepository(requestsHandler, 'profile', options.authBaseUrl);

    this.mConsetScopesRepository = new Repository<IConsentScopes, IConsentScopesDto, {}>(requestsHandler, {
      rootResourcePath: 'consentScopes',
      adaptor: new ConsentScopesAdaptor()
    });
    this.mProfileExternalAppsRepository = new Repository<IProfileExternalApps, ProfileExternalAppsDto, {}>(requestsHandler, {
      rootResourcePath: (pathParams: any): string => {
        const { id, consentId } = pathParams || {};
        if (id && consentId) {
          return `users/${id}/consents/${consentId}`;
        }
        if (id && !consentId) {
          return `users/${id}/consents`;
        }
        return '';
      },
      adaptor: new ProfileExternalAppsAdaptor()
    });
    this.mConsentRepository = new Repository<IConsent, ConsentDto, {}>(requestsHandler, {
      rootResourcePath: 'consent/activate',
      adaptor: new ConsentAdaptor()
    });
  }
}
