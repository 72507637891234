/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable class-methods-use-this */
import { IAdaptor } from '../utils/IAdaptor';
import { IEula } from '../../../domain/models/IEula';
import EulaDto from '../../dto/EulaDto';

export default class EulaAdaptor implements IAdaptor<IEula, EulaDto> {
    /**
     * This property is used to map properties from FE to properties in BE, such that when requesting some fields,
     * or filtering by some fields, the infrastructure layer knows what to request from the BE.
     * */
    domainToDtoPropertiesMap: { [K in keyof IEula]?: keyof EulaDto } = {
      action: 'action'
    };

    /**
     * This is used mostly when we do get calls, so that data from BE is properly mapped to data in FE
     */
    fromDto(dto: EulaDto): Partial<IEula> {
      return {
        action: dto.action
      };
    }

    /**
     * This function is used to map FE Domain models to BE models.
     * It will be called when we send a domain model to the infrastructure, and it needs to send a Dto to BE (post, patch, put).
     * Bear in mind that you can just write the properties that you use,
     * no need to add the properties that you don't need to send over the network.
     */
    toDto(eula: Partial<IEula>): Partial<EulaDto> {
      return {
        action: eula.action
      };
    }
}
