/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

import React, { FC, useState } from 'react';
import {
  IlDataTableV2Headers, IlStates, IlDataSource
} from 'il-framework-component';
import { useTranslation } from 'react-i18next';
import TableComponentV2 from '../../../../app/shared/components/TableComponent/TableComponentV2';
import IconV2, { IconV2SizeEnum } from '../../../../app/shared/components/Icon/IconV2';
import { I18nNamespaces } from '../../../../app/shared/utils/constants.util';
import { IProfileExternalApps, IScopes } from '../../../../domain/models/IProfileExternalApps';
import RemoveExternalAppModal from '../RemoveExternalAppModal/RemoveExternalAppModal';
import styles from './externalAppsAccessTable.scss';

interface ExternalAppsAccessProps {
    externalApplications: IProfileExternalApps[];
}

const ExternalAppsAccess: FC<ExternalAppsAccessProps> = ({ externalApplications }) => {
  const { t } = useTranslation(I18nNamespaces.PROFILE);
  const [isRemoveAppAccessModalOpen, setIsRemoveAppAccessModalOpen] = useState<boolean>(false);
  const [selectedExternalApp, setSelectedExternalApp] = useState<IProfileExternalApps>();

  const getInnerAppsNames = (scopes: IScopes[]) => {
    if (scopes.length > 0) {
      const names = scopes.map((el: IScopes) => el.name);
      names.unshift('Email');
      return names.join(', ');
    }
    return t('externalApps.profileAccessOnlyTo');
  };

  const convertToDate = (date: string) => {
    const dateOptions = {
      year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false
    };
    const accessGivenOnDate = new Date(date);
    return new Intl.DateTimeFormat('en-US', dateOptions).format(accessGivenOnDate);
  };

  const deleteIconTooltip = (
    <span>
      {t('externalApps.removeAccess')}
    </span>
  );

  const closeRemoveAppAccessModalHandler = (): void => {
    setIsRemoveAppAccessModalOpen(false);
  };

  const openRemoveAppAccessModalHandler = (selectedApp: IProfileExternalApps): void => {
    setSelectedExternalApp(selectedApp);
    setIsRemoveAppAccessModalOpen(true);
  };

  const headers: IlDataTableV2Headers[] = [
    {
      key: 'extAppName',
      header: t('externalApps.appName'),
      renderCell: (rowData) => rowData?.extAppName || '-'
    },
    {
      key: 'scopes',
      header: t('externalApps.hasAccess'),
      renderCell: (rowData) => (rowData?.scopes ? getInnerAppsNames(rowData?.scopes) : '')
    },
    {
      key: 'createdAt',
      header: t('externalApps.accessGiven'),
      renderCell: (rowData) => (rowData?.createdAt ? convertToDate(rowData?.createdAt) : '-')
    },
    {
      key: 'actions',
      header: t('externalApps.appActions'),
      alignment: 'center',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderCell: (rowData) => (
        <button
          type='button'
          onClick={() => openRemoveAppAccessModalHandler(rowData as IProfileExternalApps)}
          aria-label='Action Icon'
          className={styles['delete-btn']}
        >
          <IconV2
            name='delete-v1'
            className='iconFillPrimary'
            size={IconV2SizeEnum.Medium}
            tooltip={{
              body: deleteIconTooltip,
              placement: 'top'
            }}
          />
        </button>
      )
    }
  ];

  const tableExpansion = {
    className: 'custom-expandable-row',
    isActive: true,
    renderExpandedRow: (rowData: IlDataSource) => {
      if (rowData?.scopes) {
        const { scopes } = rowData;
        const element = scopes.map((app: IScopes) => (
          <div className={styles['expanded-row']} key={app.name}>
            <h6 className={styles['innerapp-title']}>{app.name}</h6>
            <p>{app.description}</p>
          </div>
        ));
        if (scopes.length === 0) {
          return (
            <div className={styles['expanded-row']}>
              <h6 className={styles['innerapp-title']}>Profile Email</h6>
              <p>{t('externalApps.profileAccessOnly')}</p>
            </div>
          );
        }
        return element;
      };
      return <></>;
    }
  };

  return (
    externalApplications.length > 0 ? (
      <>
        {isRemoveAppAccessModalOpen && (
        <RemoveExternalAppModal
          closeModal={closeRemoveAppAccessModalHandler}
          selectedExternalApp={selectedExternalApp as IProfileExternalApps}
        />
        )}
        <TableComponentV2<IProfileExternalApps>
          headers={headers}
          expansion={tableExpansion}
          dataSource={externalApplications}
          title={t('externalApps.title')}
        />
      </>
    ) : (
      <IlStates
        title={t('externalApps.noExternalAppsTitle')}
        description={t('externalApps.noExternalAppsDescr')}
        illustration='empty'
      />
    )
  );
};

export default ExternalAppsAccess;
