/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable class-methods-use-this */
import { IAdaptor } from '../utils/IAdaptor';
import { IConsent } from '../../../domain/models/IConsent';
import ConsentDto from '../../dto/consentDto';

export default class ConsentAdaptor implements IAdaptor<IConsent, ConsentDto> {
    /**
     * This property is used to map properties from FE to properties in BE, such that when requesting some fields,
     * or filtering by some fields, the infrastructure layer knows what to request from the BE.
     * */
    domainToDtoPropertiesMap: { [K in keyof IConsent]?: keyof ConsentDto } = {
      action: 'action',
      scopes: 'scopes'
    };

    /**
     * This is used mostly when we do get calls, so that data from BE is properly mapped to data in FE
     */
    fromDto(dto: ConsentDto): Partial<IConsent> {
      return {
        action: dto.action,
        scopes: dto.scopes
      };
    }

    /**
     * This function is used to map FE Domain models to BE models.
     * It will be called when we send a domain model to the infrastructure, and it needs to send a Dto to BE (post, patch, put).
     * Bear in mind that you can just write the properties that you use,
     * no need to add the properties that you don't need to send over the network.
     */
    toDto(consent: Partial<IConsent>): Partial<ConsentDto> {
      return {
        action: consent.action,
        scopes: consent.scopes
      };
    }
}
