/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Copyright 2022, IntraLinks, Inc. All rights reserved.
 *
 */

import * as React from 'react';
import {
  IlDataTableV2Headers,
  IlDataSource,
  IlDataTableV2,
  IlDataTableV2Toolbar,
  IlDataTableV2BatchActions,
  IlDataTableV2Expansion
} from 'il-framework-component';
import {
  IlDataTableV2Pagination
} from 'il-framework-component/src/components/IlDataTableV2';

export interface ITableComponentV2Props <T extends IlDataSource> {
  title: string;
  description?: React.ReactNode;
  className?: string;
  id?: string;
  headers: IlDataTableV2Headers[];
  dataSource: T[];
  toolbar?: IlDataTableV2Toolbar;
  pagination?: IlDataTableV2Pagination;
  batchActions?: IlDataTableV2BatchActions;
  expansion?: IlDataTableV2Expansion;
  onRenderProps?: (dataTableCustomRenderProps: unknown) => void;
}

const TableComponentV2 = <T extends IlDataSource>(props: ITableComponentV2Props<T>): JSX.Element => {
  const {
    headers,
    dataSource,
    title,
    toolbar,
    pagination,
    description,
    id,
    className,
    batchActions,
    expansion
  } = props;

  return (
    <IlDataTableV2
      expansion={expansion}
      batchActions={batchActions}
      headers={headers}
      dataSource={dataSource}
      title={title}
      id={id}
      className={className}
      description={description}
      toolbar={toolbar}
      pagination={pagination}
    />
  );
};

TableComponentV2.defaultProps = {
  batchActions: {
    hasSelectRow: false,
    hasSelectAll: false
  },
  description: '',
  className: '',
  id: '',
  toolbar: {},
  onRenderProps: () => null
};

export default TableComponentV2;
