/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable class-methods-use-this */
import { IAdaptor } from '../utils/IAdaptor';
import { IProfile } from '../../../domain/models/IProfile';
import ProfileDto from '../../dto/ProfileDto';

export default class ProfileAdaptor implements IAdaptor<IProfile, ProfileDto> {
  /**
   * This property is used to map properties from FE to properties in BE, such that when requesting some fields,
   * or filtering by some fields, the infrastructure layer knows what to request from the BE.
   * */
  domainToDtoPropertiesMap: { [K in keyof IProfile]?: keyof ProfileDto } = {
    // id: 'id',
    externalId: 'externalId',
    applications: 'applications',
    eulaVersion: 'eulaVersion',
    eulaDeclined: 'eulaDeclined',
    name: 'name',
    preferences: 'preferences'
  };

  /**
   * This is used mostly when we do get calls, so that data from BE is properly mapped to data in FE
   */
  fromDto(dto: ProfileDto): Partial<IProfile> {
    return {
      id: dto.id || '',
      externalId: dto.externalId,
      preferences: dto.preferences,
      name: dto.name,
      phoneInfos: dto.phoneInfos,
      addresses: dto.addresses,
      identityProviderId: dto.identityProviderId,
      ssoEnabled: dto.ssoEnabled,
      title: dto.title,
      industry: dto.industry,
      applications: dto.applications,
      eulaVersion: dto.eulaVersion,
      eulaDeclined: dto.eulaDeclined
    };
  }

  /**
   * This function is used to map FE Domain models to BE models.
   * It will be called when we send a domain model to the infrastructure, and it needs to send a Dto to BE (post, patch, put).
   * Bear in mind that you can just write the properties that you use,
   * no need to add the properties that you don't need to send over the network.
   */
  toDto(profile: Partial<IProfile>): Partial<ProfileDto> {
    return {
      name: profile.name,
      addresses: profile.addresses,
      preferences: profile.preferences,
      externalId: profile.externalId,
      id: profile.id,
      phoneInfos: profile.phoneInfos,
      title: profile.title,
      industry: profile.industry,
      applications: profile.applications,
      eulaVersion: profile.eulaVersion,
      eulaDeclined: profile.eulaDeclined
    };
  }
}
