/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

import Axios from 'axios';
import { ENDPOINT_PREFIX } from './constants.util';

declare const BUILD_ID: string | undefined;

export interface IConfig {
  serverPort: number;
  NODE_ENV: string;
  appName: string;
  appId: string;
  profileAppName: string;
  apiBaseUrl: string;
  iamConfig: {
    logout: string;
    apiBaseUrl: string;
  };
  gatewayBaseUrl?: string;
  csrfEnabled: boolean;
  authBaseUrl: string;
  gainsightKey: string;
  gainsightAnalyticsUrl: string;
  analyticsKeysPath: string;
}

let config: IConfig;

/**
 * @desc Returns the config of the app, based on the environment variable present in server.js.
 */
export async function initialize(): Promise<IConfig> {
  await Axios.get(`${ENDPOINT_PREFIX}/__config__?v=${BUILD_ID}`).then((response) => {
    config = response.data;
  });
  return config;
}

/**
 * @desc Helper method to gain access to the dev environment provided globally
 * by webpack.
 * @returns {Object}
 */

export function getConfig(): IConfig {
  return config;
}
