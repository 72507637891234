/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
import i18n, { TFunction } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { I18nNamespaces, ENDPOINT_PREFIX } from './app/shared/utils/constants.util';

declare const BUILD_ID: string | undefined;

// eslint-disable-next-line arrow-body-style
export const initializeI18n = (): Promise<TFunction> => {
  return i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `${ENDPOINT_PREFIX}/locales/{{lng}}/{{ns}}.json?v=${BUILD_ID}`
      },
      fallbackLng: 'en-US',
      lng: 'en-US',
      load: 'currentOnly',
      ns: [
        I18nNamespaces.DEFAULT
      ],
      defaultNS: I18nNamespaces.DEFAULT,
      debug: false,
      interpolation: {
        escapeValue: false
      },
      react: {
        transEmptyNodeValue: '',
        wait: true,
        useSuspense: false,
        transSupportBasicHtmlNodes: false
      }
    });
};

export default i18n;
