/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
import React from 'react';
import { WithTranslation } from 'react-i18next';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { ERROR_PATHS } from '../../app/shared/utils/constants.util';

type ErrorPageProps = WithTranslation;
interface QueryString {
    url?: string;
    method?: string;
}

class ErrorPage extends React.Component<ErrorPageProps> {
    errorContent: string | undefined = this.getErrorContent();

    errorPath: string = window.location.pathname.split('errors/')[1];

    errorCode: string = ERROR_PATHS.find((el) => el === this.errorPath) || '';

    constructor(props: ErrorPageProps) {
      super(props);
    }

    // eslint-disable-next-line class-methods-use-this
    getErrorContent(): string | undefined {
      const path: QueryString = queryString.parse(window.location.search);
      return path.url || path.method;
    };

    render() {
      return (
        <>
          <Redirect
            to={{
              pathname: '/error',
              search: window.location.search,
              state: {
                path: this.errorCode || '500',
                content: this.errorContent
              }
            }}
          >
            {' '}
          </Redirect>
        </>
      );
    }
}

export default ErrorPage;
