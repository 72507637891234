/* eslint-disable react/prop-types */
/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IlLoading } from 'il-framework-component';
import ExternalAppsAccessTable from './ExternalAppsAccessTable/ExternalAppsAccessTable';
import * as Styles from '../../../scss/Common.scss';
import * as styles from './externalAppsAccess.scss';
import { RootState } from '../../../app/store';
import { setIsLoading } from '../../../app/shared/AppSlice/AppSlice';
import { getExternalApps } from '../profileSlice';
import { I18nNamespaces } from '../../../app/shared/utils/constants.util';

// eslint-disable-next-line arrow-body-style
const ExternalApplications: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(I18nNamespaces.PROFILE);

  const { id } = useSelector((state: RootState) => state.profile.profileDetails);
  const externalApps = useSelector((state: RootState) => state.profile.externalApps);
  const isAppLoading = useSelector((state: RootState) => state.appState.isAppLoading);
  const [isAppLoadingDone, setIsAppLoadingDone] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      await dispatch(setIsLoading(true));
      if (id) {
        await dispatch(getExternalApps(id, t));
        await setIsAppLoadingDone(true);
      }
      await dispatch(setIsLoading(false));
    })();
  }, [id]);

  return (
    <div className={Styles.container}>
      { isAppLoading && <IlLoading withOverlay />}
      {!isAppLoading && isAppLoadingDone
      && (
      <div className={styles.updateCard}>
        <ExternalAppsAccessTable externalApplications={externalApps} />
      </div>
      )}
    </div>
  );
};

export default ExternalApplications;
