/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../../app/store';
import Repos from '../../../infrastructure/repositories/Repos';
import { getTranslatedErrorKey } from '../../../app/shared/utils/errors.util';

export interface IUpdatePasswordState {
  loading: boolean;
  updateRequestSuccess: boolean;
  updateRequestError: string;
}

const initialState: IUpdatePasswordState = {
  loading: false,
  updateRequestSuccess: false,
  updateRequestError: ''
};

export const updatePasswordSlice = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {
    setLoading: (state: IUpdatePasswordState, action: PayloadAction<boolean>): void => {
      state.loading = action.payload;
    },
    setUpdateRequestError: (state: IUpdatePasswordState, action: PayloadAction<string>): void => {
      state.updateRequestError = action.payload;
    },
    setUpdateRequestSuccess: (state: IUpdatePasswordState, action: PayloadAction<boolean>): void => {
      state.updateRequestSuccess = action.payload;
    }
  }
});

export const {
  setLoading,
  setUpdateRequestError,
  setUpdateRequestSuccess
} = updatePasswordSlice.actions;

// export const selectUserId = (state: RootState): string => state.login.userId;
// export const selectToken = (state: RootState): string => state.login.token;

export const updatePasswordReq = (oldPassword: string, newPassword: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(setLoading(true));
  dispatch(setUpdateRequestError(''));
  dispatch(setUpdateRequestSuccess(false));
  try {
    await Repos.instance.recoveryRepository.resetPassword(oldPassword, newPassword);
    dispatch(setUpdateRequestSuccess(true));
  } catch (err) {
    const translatedErrorKey = getTranslatedErrorKey(err);
    dispatch(setUpdateRequestError(translatedErrorKey));
  }
  dispatch(setLoading(false));
};

export default updatePasswordSlice.reducer;
