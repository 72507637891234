/*
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
import * as forge from 'node-forge';

// eslint-disable-next-line import/prefer-default-export
export const checkComplexity = (password: string): { score: number; repeatedCharacters: boolean } => {
  // TODO: check for forbidden chars?
  // TODO: check against leaked passwords
  let score = password !== '' ? 1 : 0;
  let repeatedCharacters = false;
  if (password.length >= 1 && password.length <= 5) {
    score = 1;
  }
  if (password.length >= 6 && password.length <= 9) {
    score = 2;
  }
  if (password.length >= 10 && password.length <= 12) {
    score = 3;
  }
  if (password.length > 12) {
    score = 4;
  }
  if (password.match(/((.)\2{2,})/)) {
    repeatedCharacters = true;

    if (score > 2) {
      score = 2;
    }
  }
  return { score, repeatedCharacters };
};

export const fetchExpiryDate = (certificate: string) => {
  const getCertificate = (certificate.toUpperCase().includes('BEGIN') && certificate.toUpperCase().includes('END'))
    ? certificate : `"-----BEGIN CERTIFICATE-----${certificate}-----END CERTIFICATE-----"`;
  const certificateExpDate = forge.pki.certificateFromPem(getCertificate);
  return certificateExpDate.validity.notAfter.toISOString();
};
