/*
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable class-methods-use-this */
import { IAdaptor } from '../utils/IAdaptor';
import { IConsentScopes } from '../../../domain/models/IConsentScopes';
import IConsentScopesDto from '../../dto/IConsentScopesDto';

export default class ConsentScopesAdaptor implements IAdaptor<IConsentScopes, IConsentScopesDto> {
    /**
     * This property is used to map properties from FE to properties in BE, such that when requesting some fields,
     * or filtering by some fields, the infrastructure layer knows what to request from the BE.
     * */
    domainToDtoPropertiesMap: { [K in keyof IConsentScopes]?: keyof IConsentScopesDto } = {
      id: 'id',
      name: 'name',
      allowed: 'allowed'
    };

    /**
     * This is used mostly when we do get calls, so that data from BE is properly mapped to data in FE
     */
    fromDto(dto: IConsentScopesDto): Partial<IConsentScopes> {
      return {
        id: dto.id,
        name: dto.name,
        allowed: dto.allowed
      };
    }

    /**
     * This function is used to map FE Domain models to BE models.
     * It will be called when we send a domain model to the infrastructure, and it needs to send a Dto to BE (post, patch, put).
     * Bear in mind that you can just write the properties that you use,
     * no need to add the properties that you don't need to send over the network.
     */
    toDto(domain: Partial<IConsentScopes>): Partial<IConsentScopesDto> {
      return {
        id: domain.id,
        name: domain.name,
        allowed: domain.allowed
      };
    }
}
